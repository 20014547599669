<template>
  <div class="d-flex flex-grow-1">
    <v-form ref="priceListForm" class="d-flex flex-grow-1">
      <table class="price-list-table" data-test-id="priceListTable">
        <thead>
          <tr>
            <th v-for="(header, index) in headers" :key="index">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(season, seasonIndex) in priceLists"
            :key="seasonIndex"
            :data-test-id="'season_row_' + seasonIndex"
          >
            <td class="date-column">
              <v-text-field
                v-model="season.fromDate"
                outlined
                dense
                type="date"
                min="1900-01-01"
                hide-details="auto"
                :max="season.untilDate"
                :rules="[
                  ruleSet.required,
                  ruleSet.overlap(season),
                  ruleSet.beforeUntilDate(season),
                ]"
                :data-test-id="'season_' + seasonIndex + '_from_date'"
              />
            </td>
            <td class="date-column">
              <v-text-field
                v-model="season.untilDate"
                outlined
                dense
                type="date"
                hide-details="auto"
                :min="season.fromDate"
                :rules="[
                  ruleSet.required,
                  ruleSet.overlap(season),
                  ruleSet.afterFromDate(season),
                ]"
                :data-test-id="'season_' + seasonIndex + '_until_date'"
              />
            </td>
            <td class="price-list-translation-column">
              <!-- 
                    Defining a tabindex is needed so that the :focus-within
                    pseudo selector does work correctly
                -->
              <div
                class="price-list-translation-container"
                :ref="'translations_' + season.id"
                :data-test-id="'translations_' + seasonIndex"
                :tabindex="-1"
                @focusout="handleFocusOut('translations_' + season.id)"
              >
                <div class="d-flex flex-column">
                  <div
                    v-for="(translation, index) in translations[season.id]"
                    :key="index"
                    class="d-flex flex-row"
                    :data-test-id="
                      'translations_row_' + seasonIndex + '_' + index
                    "
                  >
                    <div
                      v-if="index === 0"
                      class="more-translations-indicator"
                      title="Expand translations"
                      data-test-id="moreTranslationsIndicator"
                    >
                      <v-icon>mdi-unfold-more-horizontal</v-icon>
                    </div>
                    <v-text-field
                      v-model="translation.locale"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Locale (e.g. en-GB)"
                      class="locale-input"
                      :ref="'locale_input_' + season.id + '_' + index"
                      :rules="[ruleSet.required, ruleSet.localePattern]"
                      :data-test-id="
                        'season_translation_locale_' + seasonIndex + '_' + index
                      "
                    />
                    <v-text-field
                      v-model="translation.text"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Text"
                      class="label-input"
                      :rules="[ruleSet.required]"
                      :data-test-id="
                        'season_translation_text_' + seasonIndex + '_' + index
                      "
                    />
                    <!-- Only mimic vuetify button to prevent losing focus when clicking and therefore closing the popup -->
                    <button
                      v-if="translations[season.id].length > 1"
                      v-ripple
                      class="
                        mx-1
                        my-1
                        v-btn v-btn--icon v-btn--round
                        theme--light
                        v-size--default
                        red--text
                      "
                      :data-test-id="
                        'season_translation_remove_btn_' +
                        seasonIndex +
                        '_' +
                        index
                      "
                      @click.prevent="removeTranslation(season.id, index)"
                    >
                      <v-icon color="red">mdi-minus</v-icon>
                    </button>
                  </div>
                </div>
                <!-- Only mimic vuetify button to prevent losing focus when clicking and therefore closing the popup -->
                <button
                  v-ripple
                  color="primary"
                  class="
                    v-btn v-btn--text
                    theme--light
                    v-size--default
                    primary--text
                  "
                  :data-test-id="'season_translation_add_btn_' + seasonIndex"
                  @click.prevent="addTranslation(season.id)"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                  <div>Add translation</div>
                </button>
              </div>
            </td>
            <td class="delete-column">
              <v-btn
                icon
                color="red"
                :data-test-id="'season_remove_btn_' + seasonIndex"
                @click="$delete(priceLists, seasonIndex)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
            <td v-if="showImport" class="import-column">
              <v-btn
                text
                color="primary"
                :disabled="importBtnDisabled"
                :loading="Boolean(runningImports[season.id])"
                :data-test-id="'season_import_btn_' + seasonIndex"
                @click="startSeasonImport(season.id)"
              >
                <v-icon left>mdi-file-download-outline</v-icon>
                Update price list
              </v-btn>
            </td>
          </tr>
          <tr>
            <td :colspan="5" class="pa-0">
              <v-btn
                text
                color="primary"
                data-test-id="addSeasonBtn"
                class="add-season-btn"
                @click="addSeason()"
              >
                <v-icon left>mdi-plus</v-icon> Add Season
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    showImport: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableImport: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      priceLists: this.value ?? [],
      translations: {},
      runningImports: {},
    };
  },

  watch: {
    priceLists: {
      handler: function (lists) {
        this.$nextTick(() => this.$refs.priceListForm.validate());
        this.$emit("input", lists);
      },
      deep: true,
    },

    translations: {
      handler: function (translations) {
        this.priceLists.forEach((season) => {
          const seasonTranslations = translations[season.id];
          if (!seasonTranslations || seasonTranslations.length === 0) {
            //no translation labels, so set name explicit to null
            season.name = null;
            return;
          }

          season.name = {};
          seasonTranslations.forEach(({ locale, text }) => {
            if (locale && text) this.$set(season.name, locale, text);
          });
        });
      },
      deep: true,
    },
  },

  mounted() {
    this.getTranslations();
  },

  methods: {
    getTranslations() {
      this.priceLists.forEach((list) => {
        if (!list.name) return;
        const map = new Map(Object.entries(list.name));
        map.forEach((text, locale) => {
          let trans = this.translations[list.id];
          if (!trans) trans = [];
          trans.push({ locale, text });
          if (navigator.language === locale) {
            //push translation in the users current locale to first position
            trans.splice(0, 0, trans.splice(trans.length - 1, 1)[0]);
          }
          this.$set(this.translations, list.id, trans);
        });
      });
    },

    handleFocusOut(id) {
      let container = this.$refs[id][0];
      //if the translation containers is about to be hidden,
      //scroll to top to only show the first translation option
      if (getComputedStyle(container).overflow !== "hidden") return;
      container.scrollTop = 0;
    },

    addSeason() {
      const id = this.$uuid.v4();
      this.priceLists.push({
        id,
        fromDate: "",
        untilDate: "",
        name: {},
      });
      this.addTranslation(id);
    },

    addTranslation(priceList) {
      let arr = this.translations[priceList] || [];
      arr.push({ locale: "", text: "" });
      this.$set(this.translations, priceList, arr);
    },

    removeTranslation(id, index) {
      this.$delete(this.translations[id], index);
      this.$nextTick(() => {
        //focus the input of the name previous to the deleted one
        //this will keep the popup open
        const inputRef =
          "locale_input_" + id + "_" + (index > 0 ? index - 1 : 0);
        this.$refs[inputRef][0].focus();
      });
    },

    setProperty(object, property, value) {
      this.$set(object, property, value);
      this.priceLists = Object.assign([], this.priceLists);
    },

    startSeasonImport(seasonId) {
      this.$set(this.runningImports, seasonId, true);
      const done = () => this.$delete(this.runningImports, seasonId);
      //emit the season id and a callback function. The callback function is called
      //when the import is done and removes the loading state
      this.$emit("import:season", { seasonId, done });
    },
  },

  computed: {
    ruleSet() {
      return {
        required: (val) => !!val || "Value is required",
        localePattern: (val) =>
          this.$isValidLocale(val) || val + " is not a valid locale",
        overlap: (currentSeason) => {
          return (val) => {
            const valueDate = new Date(val);
            const overlap = this.priceLists
              .filter(
                (season) =>
                  season.id !== currentSeason.id &&
                  season.fromDate &&
                  season.untilDate
              )
              .some((season) => {
                const fromDate = new Date(season.fromDate);
                const untilDate = new Date(season.untilDate);
                return (
                  this.$getDayDiff(fromDate, valueDate) >= 0 &&
                  this.$getDayDiff(valueDate, untilDate) >= 0
                );
              });
            return !overlap || "Date overlaps with another season";
          };
        },
        afterFromDate: (currentSeason) => {
          return (date) => {
            const fromDate = currentSeason.fromDate;
            if (!fromDate) return true;
            return (
              !fromDate ||
              (!!date &&
                this.$getDayDiff(new Date(fromDate), new Date(date)) >= 0) ||
              "Date must be after " +
                this.$getLocalizedDate(fromDate, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
            );
          };
        },
        beforeUntilDate: (currentSeason) => {
          return (date) => {
            const untilDate = currentSeason.untilDate;
            if (!untilDate) return true;
            return (
              !untilDate ||
              (!!date &&
                this.$getDayDiff(new Date(date), new Date(untilDate)) >= 0) ||
              "Date must be before " +
                this.$getLocalizedDate(untilDate, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
            );
          };
        },
      };
    },

    headers() {
      let headers = ["From Date", "Until Date", "Name", ""];
      if (this.showImport) headers.push("");
      return headers;
    },

    importBtnDisabled() {
      return this.disableImport || Object.keys(this.runningImports).length > 0;
    },
  },
};
</script>

<style scoped>
.price-list-table {
  flex: 0 1 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.price-list-table th {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  height: 32px;
  padding: 0 16px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.75rem;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.price-list-table .date-column {
  padding: 0 12px;
  width: 250px;
}

.price-list-table .delete-column {
  width: 40px;
}

.price-list-table .import-column {
  width: 200px;
}

.price-list-table .enabled-column {
  width: 78px;
  padding-left: 27px;
  padding-right: 27px;
}

.price-list-add-translation-btn {
  display: flex;
  width: 100%;
}

.price-list-translation-container {
  background-color: white;
  display: flex;
  flex-flow: column;
  padding: 0px 12px;
}

.price-list-translation-container:hover {
  background-color: #eeeeee;
}

.add-season-btn.v-btn.v-size--default {
  height: 48px;
  width: 100%;
}

.price-list-translation-column {
  position: relative;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
}

.price-list-translation-container {
  height: 48px;
  z-index: 6;
  width: 100%;
  overflow: hidden;
}

.price-list-translation-container .label-input,
.price-list-translation-container .locale-input {
  display: flex;
  margin: 4px;
}

.price-list-translation-container .label-input {
  flex-grow: 10;
}

.price-list-translation-container:focus-within {
  height: auto;
  max-height: 250px;
  width: 100%;
  overflow: scroll;
  z-index: 20;
  border-color: rgb(46, 117, 212);
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  outline: none;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px;
}

.price-list-translation-container:focus-within .price-list-translation-box {
  display: block;
}

.more-translations-indicator {
  display: flex;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
}

.price-list-translation-container:focus-within .more-translations-indicator {
  display: none;
}
</style>