<template>
  <v-container
    v-resize="onResize"
    fluid
    fill-height
    class="product-mapper-dialog"
  >
    <v-toolbar class="product-mapper-toolbar" dense>
      <v-toolbar-title class="white--text">
        Mapping rules of {{ importConfiguration.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="white" @click="helpDialog = true">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
      <v-btn
        icon
        color="white"
        data-test-id="closeProductMapperBtn"
        :disabled="saving"
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid fill-height class="product-mapper-container">
      <!-- RULES TABLE TOOLBAR -->
      <v-container fluid class="rule-container">
        <v-container fluid class="rule-toolbar elevation-4">
          <v-toolbar flat>
            <v-select
              class="product-limit-select"
              dense
              hide-details
              v-model="limit"
              :items="pageLimits"
              :disabled="checkingRules"
              data-test-id="pageLimitSelector"
            />
            entries per page
            <v-divider class="mx-3" vertical></v-divider>
            <!-- CUSTOM PAGINATION COMPONENT -->
            <PaginationComponent
              v-if="totalPages > 0"
              v-model="currentPage"
              :totalPages="totalPages"
            />
            <v-divider class="mx-3" v-if="totalPages > 0" vertical></v-divider>
            <span>{{ total }} entries found</span>
            <v-divider class="mx-3" vertical></v-divider>
            <div class="hide-checkbox-container">
              <v-checkbox
                v-model="hideUnmapped"
                hide-details
                label="Hide unmapped"
                class="hide-unmapped-checkbox"
                data-test-id="hideUnmappedCheckbox"
                :disabled="checkingRules"
              />
              <v-checkbox
                v-model="hideSkipped"
                hide-details
                label="Hide skipped"
                class="hide-skipped-checkbox"
                data-test-id="hideSkippedCheckbox"
                :disabled="checkingRules"
              />
            </div>
            <v-divider class="mx-3" vertical></v-divider>
            <v-checkbox
              v-model="showInvalid"
              hide-details
              label="Show only invalid"
              class="show-invalid-checkbox"
              data-test-id="showInvalidCheckbox"
              :disabled="checkingRules"
            />
            <v-divider class="mx-3" vertical></v-divider>
            <v-menu
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-if="hasPriceData"
                  v-model="date"
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="date-picker"
                  data-test-id="datePicker"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" />
            </v-menu>
            <v-select
              v-if="hasPriceData"
              v-model="currency"
              :items="currencies"
              item-value="code"
              item-text="code"
              outlined
              dense
              hide-details
              class="currency-input"
              data-test-id="currencySelector"
            />
            <v-divider v-if="hasPriceData" class="mx-3" vertical />
            <v-btn
              icon
              @click="checkAllRules()"
              :loading="checkingRules"
              :disabled="checkingRules"
              data-test-id="reloadMappingRulesBtn"
            >
              <v-icon>mdi-sync</v-icon>
            </v-btn>
            <v-btn
              icon
              data-test-id="showJsonBtn"
              color="black"
              @click="showJson = !showJson"
            >
              <v-icon v-if="!showJson">mdi-code-json</v-icon>
              <v-icon v-else>mdi-table</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              data-test-id="saveMappingRulesBtn"
              :disabled="saving"
              :loading="saving"
              :color="hasError ? 'error' : 'green'"
              :outlined="!isDirty && !hasError"
              @click="save"
            >
              <ViolationAlert
                v-if="hasError"
                :violation="violation"
                color="white"
                alignment="left"
              />
              Save
            </v-btn>
          </v-toolbar>
        </v-container>

        <!-- RULE TABLE BODY -->
        <v-container fluid class="rule-table-container" v-if="!showJson">
          <v-form ref="ruleForm">
            <v-simple-table
              class="rule-table"
              dense
              data-test-id="mappingRulesTable"
            >
              <template v-slot:default>
                <thead>
                  <th class="filler-header"></th>
                  <!-- eslint-disable-next-line -->
                  <th
                    v-for="attribute in inputVariationAttributes"
                    :class="{
                      'input-variation-attribute-header': true,
                      'grouped-by':
                        groupBy === 'variationAttributes.' + attribute.key,
                    }"
                    @click="setGroupBy('variationAttributes.' + attribute.key)"
                  >
                    {{ attribute.key }}
                    <v-icon
                      right
                      v-if="groupBy === 'variationAttributes.' + attribute.key"
                    >
                      {{ groupDesc ? "mdi-arrow-down" : "mdi-arrow-up" }}
                    </v-icon>
                  </th>
                  <th class="filler-header"></th>
                  <th class="skip-header">
                    <v-simple-checkbox
                      v-model="skipAllRules"
                      :ripple="false"
                      title="Skip all rules"
                      hide-details
                      class="skip-rule-checkbox"
                      data-test-id="skipAllRulesCheckbox"
                    />
                  </th>
                  <!-- eslint-disable-next-line -->
                  <th
                    v-for="attribute in outputVariationAttributes"
                    :class="{
                      'input-variation-attribute-header': true,
                      'grouped-by':
                        groupBy === 'ruleResponse.attributes.' + attribute.key,
                    }"
                    @click="
                      setGroupBy('ruleResponse.attributes.' + attribute.key)
                    "
                  >
                    {{ attribute.key }}
                    <v-icon
                      right
                      v-if="
                        groupBy === 'ruleResponse.attributes.' + attribute.key
                      "
                    >
                      {{ groupDesc ? "mdi-arrow-down" : "mdi-arrow-up" }}
                    </v-icon>
                  </th>
                  <th class="filler-header"></th>
                </thead>
                <tbody>
                  <tr
                    v-for="(rule, index) in multiRules"
                    :key="index"
                    :class="{
                      rule: true,
                      selected: rule.name === selectedRule,
                    }"
                    :data-test-id="'mapping_rule_row_' + rule.name"
                    @click="selectRule(rule)"
                  >
                    <td>
                      <v-chip class="rule-name">{{ rule.name }}</v-chip>
                    </td>
                    <!-- eslint-disable-next-line -->
                    <td
                      class="input-variation-attribute"
                      v-for="attribute in inputVariationAttributes"
                    >
                      <RegexEditor
                        v-model="rule.in[attribute.key]"
                        :attribute="attribute"
                        :data-test-id="
                          'input_variation_attribute_editor_' + attribute.key
                        "
                      />
                    </td>
                    <td>
                      <v-icon class="mapper-arrow" large
                        >mdi-arrow-right-thin-circle-outline</v-icon
                      >
                    </td>
                    <td>
                      <v-simple-checkbox
                        :value="rule.skip"
                        :ripple="false"
                        :data-test-id="'skip_rule_' + rule.name + '_checkbox'"
                        title="Skip rule"
                        hide-details
                        class="skip-rule-checkbox"
                        @input="skipRule(rule, $event)"
                      />
                    </td>
                    <!-- eslint-disable-next-line -->
                    <td
                      class="output-variation-attribute"
                      v-for="attribute in outputVariationAttributes"
                    >
                      <DimensionsComboBox
                        v-model="rule.out[attribute.key]"
                        :skip="rule.skip"
                        :attribute="attribute"
                        :data-test-id="
                          'output_variation_attribute_editor_' + attribute.key
                        "
                      />
                    </td>
                    <td>
                      <v-btn
                        icon
                        @click="removeRule(index)"
                        color="red"
                        :data-test-id="
                          'mapping_rule_' + rule.name + '_delete_btn'
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :colspan="
                        inputVariationAttributes.length +
                        outputVariationAttributes.length +
                        4
                      "
                      class="pa-0"
                    >
                      <v-btn
                        text
                        color="primary"
                        data-test-id="addMappingRuleBtn"
                        class="add-rule-btn"
                        :disabled="checkingRules"
                        @click="addRule(false)"
                      >
                        <v-icon left>mdi-plus</v-icon> Add Rule
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </v-container>

        <!-- RULE JSON EDITOR -->
        <v-container fluid v-else class="rule-json-container">
          <textarea
            id="ruleJsonEditor"
            data-test-id="productMapperJsonEditor"
            class="rule-json-editor"
          />
        </v-container>
      </v-container>

      <!-- ERROR BANNER -->
      <v-alert
        type="error"
        v-if="conflict || hasInvalidProducts"
        color="error"
        class="conflict-alert"
      >
        <div v-if="conflict" data-test-id="conflictAlert">
          {{ conflictMsg }}
        </div>
        <div v-else data-test-id="invalidAlert">{{ invalidProductsMsg }}</div>
      </v-alert>

      <!-- PRODUCTS TABLE -->
      <v-container
        fluid
        v-if="products && products.length > 0"
        data-test-id="productMappingTable"
        :class="{
          'product-mapping-table-container': true,
          conflict,
        }"
      >
        <v-data-table
          dense
          fixed-header
          item-key="externalId"
          hide-default-footer
          :headers="productTableHeaders"
          :items="shownProducts"
          :group-by="groupBy"
          :group-desc="groupDesc"
          :items-per-page="limit"
          :page="currentPage"
          :height="productTableHeight"
          :class="{
            products: true,
            'hide-unmapped': hideUnmapped,
          }"
          :loading="checkingRules"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:header.filler="header">
            <th class="filler-header"></th>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:group.header="{ items, isOpen, toggle, group }">
            <th
              :colspan="productTableHeaders.length"
              :class="{
                unmapped:
                  !group ||
                  !items.some(
                    (item) =>
                      item.ruleResponse && item.ruleResponse.complete === true
                  ),
              }"
            >
              <v-icon @click="toggle">
                {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
              </v-icon>
              {{ group ? group : "NO VALUE" }}
            </th>
          </template>

          <template v-slot:item="{ item, index }">
            <tr
              :class="{
                product: true,
                selected: item._selected,
                skip: item.ruleResponse && item.ruleResponse.skip,
                unmapped: !isMapped(item),
                conflict: item.ruleResponse && item.ruleResponse.conflict,
              }"
              :data-test-id="'product_variation_' + item.externalId"
            >
              <!-- Product variation input -->
              <template
                v-for="(attribute, iVAIndex) in inputVariationAttributes"
              >
                <td
                  v-if="groupBy != 'variationAttributes.' + attribute.key"
                  :key="index + attribute.key + iVAIndex"
                  :class="{
                    'input-variation-attribute': true,
                    'rule-match': inputMatchesRule(item, attribute),
                  }"
                  :data-test-id="
                    'input_variation_attribute_column_' + attribute.key
                  "
                >
                  <span>{{ item.variationAttributes[attribute.key] }}</span>
                </td>
              </template>
              <td>
                <v-chip
                  v-if="item.prices && item.prices.length > 0"
                  color="psgreen"
                  dark
                  label
                  @click="openPriceCalendar(item)"
                  small
                  :data-test-id="'current_price_' + item.externalId"
                >
                  {{ item.currentPrice || "-" }}
                </v-chip>
              </td>
              <td class="mapper-arrow-column">
                <div class="mapper-arrow-container">
                  <v-icon
                    class="mapper-arrow"
                    large
                    :color="isMapped(item) ? 'psgreen' : ''"
                    :data-test-id="
                      (isMapped(item)
                        ? 'mapped_product_'
                        : 'unmapped_product_') + item.externalId
                    "
                    @click="createSingleRule(item)"
                  >
                    mdi-arrow-right-thin-circle-outline
                  </v-icon>
                  <v-chip
                    class="rule-name"
                    v-if="item.ruleResponse && item.ruleResponse.singleRule"
                  >
                    {{ item.ruleResponse.singleRule.name }}
                  </v-chip>
                </div>
              </td>
              <!-- Product variation output -->
              <td
                v-for="(attribute, oVAIndex) in outputVariationAttributes"
                class="output-variation-attribute"
                :key="index + attribute.key + oVAIndex"
                :data-test-id="
                  'output_variation_attribute_column_' + attribute.key
                "
              >
                <DimensionsComboBox
                  v-if="item.ruleResponse && item.ruleResponse.singleRule"
                  :value="item.ruleResponse.singleRule.out[attribute.key]"
                  :attribute="attribute"
                  :skip="item.ruleResponse.skip"
                  @input="setSingleRuleAttribute(item, attribute, $event)"
                  @focus="selectRule(item.ruleResponse.singleRule)"
                  :data-test-id="
                    'single_rule_' +
                    attribute.key +
                    '_' +
                    item.ruleResponse.singleRule.name
                  "
                />
                <div class="mapped-attribute">
                  <v-chip
                    class="rule-name"
                    v-if="outputMatchesRule(item, attribute)"
                    :data-test-id="
                      'mapped_rule_' +
                      getMappedRuleName(item, attribute) +
                      '_attribute_' +
                      attribute.key
                    "
                  >
                    {{ getMappedRuleName(item, attribute) }}
                  </v-chip>
                  <span
                    :data-test-id="
                      'mapped_value_' + attribute.key + '_' + item.externalId
                    "
                  >
                    {{ getMappedValue(item, attribute) }}
                  </span>
                  <v-icon
                    v-if="isInvalidProductVariationAttribute(item, attribute)"
                    color="error"
                    right
                  >
                    mdi-alert
                  </v-icon>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>

      <!-- PRICE DATA DIALOG -->
      <v-dialog fluid v-model="showPriceData">
        <v-card class="price-data-card">
          <v-toolbar flat>
            <v-toolbar-title>Price Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer />
            <v-btn
              text
              @click="showPriceData = false"
              data-test-id="closePriceCalendarBtn"
            >
              Close
            </v-btn>
          </v-toolbar>
          <PriceCalendar
            v-if="showPriceData"
            show-names
            :key="selectedProductVariation.externalId"
            :prices="selectedProductVariation.prices"
            data-test-id="priceCalendar"
          />
        </v-card>
      </v-dialog>

      <!-- HELP DIALOG -->
      <v-dialog fluid v-model="helpDialog" :width="windowSize.x * 0.5">
        <v-card class="help-card">
          <v-card-title>Help</v-card-title>
          <v-card-text>
            Two or more product variations must not be mapped to the same output
            dimensions! This will lead to a conflict! <br />
            Use RegEx for expressions with * as shorthand for .*<br />
            The last matching rule wins.<br />
            Use the JSON editor {...} to copy from/to other places.<br />
            Use <strong>$ + input attribute name</strong> as expression in rule
            output to copy the value from the given input attribute<br />
            Group products by clicking on one of the headers in the rule
            table<br />
            Click on the mapper arrow in the products table to create a single
            rule <br />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="helpDialog = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import RegexEditor from "./controls/RegexEditor";
import DimensionsComboBox from "./controls/DimensionsComboBox";
import PaginationComponent from "components/PaginationComponent";
import PriceCalendar from "components/products/PriceCalendar";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";

import ruleChecker from "mixins/rule-checker";

import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/xq-light.css";
import "codemirror/mode/javascript/javascript.js";

export default {
  mixins: [ruleChecker],

  props: {
    spec: {
      type: Object,
      required: true,
    },

    importConfiguration: {
      type: Object,
      required: true,
    },

    products: {
      type: Array,
      required: true,
    },

    inputVariationAttributes: {
      type: Array,
      required: true,
    },

    outputVariationAttributes: {
      type: Array,
      required: true,
    },
  },

  components: {
    RegexEditor,
    DimensionsComboBox,
    PaginationComponent,
    PriceCalendar,
    ViolationAlert,
  },

  data() {
    return {
      rules: [],
      savedRule: [],
      currentPage: 1,
      limit: 100,
      showJson: false,
      editor: null,
      timeout: 0,
      hideUnmapped: false,
      hideSkipped: false,
      showInvalid: false,
      conflict: false,
      helpDialog: false,
      groupBy: null,
      groupDesc: false,
      checkingRules: false,
      selectedRule: null,
      selectedProductVariation: null,
      showPriceData: false,
      update: 0,
      currency: "EUR",
      date: "",
      saving: false,
      isDirty: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      skipAllRules: false,
      violation: null,
      invalidProductVariationAttributes: {},
    };
  },

  mounted() {
    if (this.importConfiguration?.mappingRules) {
      this.rules = this.$cloneObject(this.importConfiguration?.mappingRules);
      this.savedRules = this.$cloneObject(this.rules);
    }

    const date = new Date().toISOString();
    this.date = date.substring(0, date.indexOf("T"));
    if (this.hasPriceData) this.calculatePrice();
  },

  watch: {
    products() {
      this.checkAllRules();
    },

    rules: {
      handler: function (rules) {
        this.checkAllRules();
        this.isDirty =
          !(this.rules.length === 0 && !this.savedRules) &&
          !this.$isEqual(rules, this.savedRules);
      },
      deep: true,
    },

    skipAllRules(skip) {
      if (!skip && this.rules.every((rule) => rule.skip)) {
        this.rules.forEach((rule) => this.$set(rule, "skip", false));
        return;
      }

      if (skip) this.rules.forEach((rule) => this.$set(rule, "skip", true));
    },

    showJson(isShown) {
      if (isShown) {
        this.$nextTick(() => {
          //create a new editor instance and set options
          this.editor = CodeMirror.fromTextArea(
            document.getElementById("ruleJsonEditor"),
            {
              height: "auto",
              lineNumbers: true,
              theme: "xq-light",
              mode: {
                name: "javascript",
                json: true,
                statementIndent: 2,
              },
            }
          );

          this.editor.on("change", (editor) => {
            window.clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
              try {
                this.rules = JSON.parse(editor.getValue());
              } catch (e) {
                console.warn(e);
              }
            }, 300);
          });

          this.editor.setValue(JSON.stringify(this.rules, null, "\t"));

          //clear history to prevent a bug where the keybinding ctrl+z would undo the initial data
          this.editor.doc.clearHistory();
          this.editor.refresh();
        });
      } else {
        //remove the editor instance if the editor is hidden
        this.editor.getWrapperElement().remove();
        this.editor = null;
      }
    },

    totalPages(pages) {
      if (this.currentPage > pages) this.currentPage = pages;
      if (this.currentPage === 0) this.currentPage = 1;
    },

    showPriceData(show) {
      if (!show) this.selectedProductVariation = null;
    },

    date() {
      this.calculatePrice();
    },

    currency() {
      this.calculatePrice();
    },
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    skipRule(rule, skip) {
      this.$set(rule, "skip", skip);
      if (this.skipAllRules && !skip) this.skipAllRules = false;
    },

    isMapped(product) {
      return product?.ruleResponse?.complete && !product?.ruleResponse?.skip;
    },

    getMappedRuleName(product, attribute) {
      return product.ruleResponse?.rules?.[attribute.key + "_rule"]?.name;
    },

    addRule(single) {
      //Get the next available name for the rule
      let nameChar = "A".charCodeAt(0) - 1;
      this.rules.forEach((rule) => {
        let charCode = rule.name.charCodeAt(0);
        if (charCode > nameChar) nameChar = charCode;
      });
      nameChar = String.fromCharCode(nameChar + 1);

      const newRule = {
        name: nameChar,
        skip: false,
        in: {},
        out: {},
        single: !!single,
      };

      this.inputVariationAttributes.forEach(
        (attribute) => (newRule.in[attribute.key] = "*")
      );
      this.outputVariationAttributes.forEach(
        (attribute) => (newRule.out[attribute.key] = "")
      );

      this.rules.push(newRule);

      if (single) return newRule;
    },

    selectRule(rule) {
      this.selectedRule = rule.name;
      this.checkAllRules();
    },

    checkAllRules() {
      this.checkingRules = true;
      try {
        this.conflict = false;

        if (this.products) {
          let index = {};
          this.products.forEach((product) => {
            const pid = product.externalId;
            const singleRule = this.rules.find(
              (rule) => rule?.pid && rule?.pid === pid
            );
            product._selected = false;
            product.ruleResponse = null;
            const ruleResponse = this.checkRules(product, this.rules, false);
            product.ruleResponse = ruleResponse;

            ruleResponse.complete = this.outputVariationAttributes.every(
              (attribute) =>
                ruleResponse.attributes?.[attribute.key] != undefined
            );

            //Validate the output variations
            this.outputVariationAttributes.forEach((attribute) => {
              const value = ruleResponse?.attributes?.[attribute.key] ?? "";
              const key = product.externalId + "_" + attribute.key;
              if (!this.validateValue(value, attribute)) {
                this.$set(this.invalidProductVariationAttributes, key, true);
              } else {
                this.$delete(this.invalidProductVariationAttributes, key);
              }
            });

            if (singleRule) {
              product.ruleResponse.singleRule = singleRule;
              for (let rule of this.rules) {
                if (rule.pid === pid) {
                  product._selected = rule.name === this.selectedRule;
                  break;
                }
              }
            }

            //Check for conflicts
            ruleResponse.conflict = false;
            if (ruleResponse.complete && !ruleResponse.skip) {
              let indexKey = "";
              this.outputVariationAttributes.forEach(
                (attribute) =>
                  (indexKey += "/" + ruleResponse.attributes?.[attribute.key])
              );
              if (index[indexKey]) {
                ruleResponse.conflict = true;
                index[indexKey].conflict = true;
                this.conflict = true;
              }

              index[indexKey] = ruleResponse;
            }

            if (!ruleResponse.match) return;
            product._selected = true;
          });
        }

        this.update++;
      } finally {
        this.checkingRules = false;
      }
    },

    removeRule(index) {
      this.rules.splice(index, 1);
    },

    async createSingleRule(product) {
      const pid = product.externalId;
      const index = this.rules.findIndex((rule) => rule.pid === pid);
      if (index > -1) {
        //Single rule exists, so delete it
        if (
          !(await this.$confirm(
            "Remove single rule?",
            "Do you want to remove this single rule?"
          ))
        )
          return;
        this.$delete(product.ruleResponse, "singleRule");
        this.removeRule(index);
        return;
      }

      if (
        !(await this.$confirm(
          "New single-rule?",
          "Do you want to add a single rule for this line?"
        ))
      )
        return;
      const singleRule = this.addRule(true);
      this.$set(singleRule, "pid", pid);
      this.inputVariationAttributes.forEach((attribute) => {
        //create RegEx for this specific attribute value by escaping existing special characters
        //and adding start of line, as well as end of line characters to the value
        const expression =
          "^" +
          product.variationAttributes[attribute.key].replace(
            /[.+?^${}()|[\]\\]/g,
            "\\$&"
          ) +
          "$";
        this.$set(singleRule.in, attribute.key, expression);
        this.$set(product.ruleResponse.matches, attribute.key, [
          singleRule.name,
        ]);
      });

      this.$set(product.ruleResponse, "singleRule", singleRule);
    },

    setGroupBy(attribute) {
      //group the products in the table
      if (this.groupBy === attribute) {
        if (!this.groupDesc) {
          this.groupDesc = true;
        } else {
          this.groupBy = null;
        }
        return;
      }

      this.groupBy = attribute;
      this.groupDesc = false;
    },

    openPriceCalendar(product) {
      const clonedProduct = this.$cloneObject(product);
      let prices = product.prices ?? [];
      prices.forEach((price) => {
        const priceListId = price.priceListId;
        const priceLists = clonedProduct?.priceLists ?? [];
        const priceList = priceLists.find(({ id }) => id === priceListId);
        this.$set(price, "id", priceList?.name?.de ?? priceListId);
      });
      this.selectedProductVariation = {
        ...clonedProduct,
        prices,
      };
      this.showPriceData = true;
    },

    calculatePrice() {
      //TODO: Take multiple price lists into account
      const date = new Date(this.date);
      const time = date.getTime();
      this.products.forEach((product) => {
        this.$delete(product, "currentPrice");
        const prices = product.prices;
        if (!prices) return;
        for (let price of prices) {
          //iterate over all products and check if the selected date is between from and until date
          if (price.currency != this.currency) continue;
          try {
            const fromDate = new Date(price.fromDate);
            const untilDate = new Date(price.untilDate);
            const isSameDay =
              this.$isSameDay(date, fromDate) ||
              this.$isSameDay(date, untilDate);
            const withinDateRange =
              time >= fromDate.getTime() && time <= untilDate.getTime();
            if (isSameDay || withinDateRange) {
              const currentPrice = this.$parseFractionUnitToString(
                price.value,
                price.currency
              );
              this.$set(product, "currentPrice", currentPrice);
              return;
            }
          } catch (e) {
            continue;
          }
        }
      });

      this.update++;
    },

    async save() {
      try {
        if (!this.validate()) return;
        this.saving = true;
        this.$set(this.importConfiguration, "mappingRules", this.rules);
        const res = await this.$store.dispatch("put", {
          path:
            "/spec/" +
            this.spec.id +
            "/importConfiguration/" +
            this.importConfiguration.id,
          body: this.importConfiguration,
          successMsg:
            "Import configuration " +
            this.importConfiguration.name +
            " saved successfully",
          returnErrors: true,
        });

        if (!res?.ok) {
          if (res?.status === 400) {
            const response = await res.json();
            const errors = response.violations;
            if (errors?.length > 0) {
              const violations = errors.map((error) => {
                return {
                  message: error.property + ": " + error.message,
                };
              }, []);
              this.violation = {
                violations,
              };
            }
          } else {
            try {
              const error = await res.json();
              this.$store.commit(
                "SET_ERROR",
                "Error " + error.code + ": " + error.message
              );
            } catch (e) {
              throw Error(
                "Something unexpected happened while saving import configuration " +
                  this.importConfiguration.name
              );
            }
          }
        } else {
          this.$emit("saved", true);
          this.isDirty = false;
          this.savedRules = this.$cloneObject(this.rules);
        }
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
      } finally {
        this.saving = false;
      }
    },

    async close() {
      if (this.isDirty) {
        if (
          !(await this.$confirm(
            "Changed rules!",
            "Mapping rules were updated! Do you want to discard the changes?"
          ))
        )
          return;
        this.$set(this.importConfiguration, "mappingRules", this.savedRules);
        this.rules = this.$cloneObject(this.savedRules);
      }
      this.$emit("close");
    },

    validate() {
      if (this.conflict) {
        this.violation = {
          message: "The rules are producing conflicts, please check",
        };
        return false;
      }

      const form = this.$refs.ruleForm;
      if (!this.$validateVForm(form)) {
        this.violation = {
          message: "At least one input is invalid, please check",
        };
        return false;
      }

      if (this.singleRules?.length > 0) {
        //Check if the single rules in the product table are valid
        const isValid = this.singleRules.every((rule) => {
          if (!rule.out) return true;
          const outputAttributes = Object.keys(rule.out);
          return outputAttributes.every((outputAttribute) => {
            const value = rule.out?.[outputAttribute];
            const attribute = this.outputVariationAttributes.find(
              ({ key }) => outputAttribute === key
            );
            return this.validateValue(value, attribute);
          });
        });

        if (!isValid) {
          this.violation = {
            message:
              "At least one single rule in the product table is invalid, please check",
          };
          return false;
        }
      }

      if (Object.keys(this.invalidProductVariationAttributes).length > 0) {
        this.violation = {
          message:
            "The rules are producing invalid attribute values, please check",
        };
        return false;
      }

      this.violation = null;
      return true;
    },

    inputMatchesRule(item, attribute) {
      return !!item?.ruleResponse?.matches?.[attribute.key]?.includes(
        this.selectedRule
      );
    },

    outputMatchesRule(item, attribute) {
      return !!item?.ruleResponse?.attributes?.[attribute.key];
    },

    setSingleRuleAttribute(item, attribute, value) {
      this.$set(item.ruleResponse.singleRule.out, attribute.key, value);
      this.selectRule(item.ruleResponse.singleRule);
    },

    getMappedValue(item, attribute) {
      return item?.ruleResponse?.attributes?.[attribute.key] ?? "";
    },

    validateValue(value, attribute) {
      if (!value) return true;
      const codePattern = attribute?.codePattern;
      if (codePattern) {
        const regex = new RegExp(codePattern);
        return regex.test(value);
      }
      return true;
    },

    isInvalidProductVariationAttribute(item, attribute) {
      const key = item.externalId + "_" + attribute.key;
      return !!this.invalidProductVariationAttributes[key];
    },
  },

  computed: {
    contentHeight() {
      //dialog height without the header
      return this.windowSize.y - 48;
    },

    productTableHeight() {
      //60% of the content height - 24px padding
      let tableHeight = this.contentHeight * 0.6 - 24;
      if (this.conflict || this.hasInvalidProducts) tableHeight -= 72;
      return tableHeight;
    },

    productTableHeaders() {
      let headers = [];
      Object.values(this.inputVariationAttributes).forEach((attr) =>
        headers.push({
          text: attr.key,
          value: "variationAttributes." + attr.key,
          align: "center",
        })
      );
      headers.push({
        name: "filler",
        value: "",
        sortable: false,
        filterable: false,
        groupable: false,
      });
      headers.push({
        name: "filler",
        value: "",
        sortable: false,
        filterable: false,
        groupable: false,
      });
      Object.values(this.outputVariationAttributes).forEach((attr) =>
        headers.push({
          text: attr.key,
          value: "ruleResponse.attributes." + attr.key,
          align: "center",
          sortable: false,
        })
      );
      return headers;
    },

    multiRules() {
      return this.rules.filter((rule) => !rule.single);
    },

    singleRules() {
      return this.rules.filter((rule) => !!rule.single);
    },

    shownProducts() {
      this.hideSkipped;
      this.hideUnmapped;
      this.showInvalid;
      this.update;

      //do this programmatically because of pagination
      return this.products.filter((product) => {
        const isInvalid = Object.keys(
          this.invalidProductVariationAttributes
        ).some((key) => {
          return key.startsWith(product.externalId);
        });

        return (
          (!this.hideUnmapped || product?.ruleResponse?.complete) &&
          (!this.hideSkipped || !product?.ruleResponse?.skip) &&
          (!this.showInvalid || isInvalid || product?.ruleResponse?.conflict)
        );
      });
    },

    hasPriceData() {
      //check if at least one object has some price data
      return this.products.some(({ prices }) => {
        return !!prices && prices.length > 0;
      });
    },

    pageLimits() {
      return [20, 50, 100, 200, 500];
    },

    totalPages() {
      return Math.ceil(this.total / this.limit);
    },

    total() {
      return this?.shownProducts?.length ?? 0;
    },

    currencies() {
      return this.$getCurrencies();
    },

    conflictMsg() {
      return "The rules produced conflicts, please check the red highlighted rows!";
    },

    hasError() {
      return !!this.violation;
    },

    hasInvalidProducts() {
      return Object.keys(this.invalidProductVariationAttributes).length > 0;
    },

    invalidProductsMsg() {
      const invalidProducts = Object.keys(
        this.invalidProductVariationAttributes
      ).reduce((invalidProducts, key) => {
        const index = key.lastIndexOf("_");
        const productId = key.substring(0, index);
        if (!invalidProducts.some((id) => id === productId)) {
          invalidProducts.push(productId);
        }
        return invalidProducts;
      }, []);

      return (
        "The rules produced " +
        invalidProducts.length +
        " mapped products with invalid attributes, please check!"
      );
    },
  },
};
</script>

<style scoped>
.product-mapper-dialog {
  padding: 0;
  display: flex;
}

.product-mapper-dialog > .v-form {
  height: 100%;
}

.product-mapper-dialog .rule-name {
  background-color: orange;
  border-color: orange;
  color: black;
}

.product-mapper-toolbar.v-toolbar {
  /* background: linear-gradient(-45deg, var(--v-psblue-base) 33%, var(--v-slpink-base) 33%, var(--v-slpink-base) 66%, var(--v-psgreen-base) 66%); */
  background: grey;
}

.product-mapper-container {
  background-color: white;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-flow: row wrap;
  padding: 0;
  height: calc(100% - 48px);
}

.product-mapper-container > .conflict-alert::v-deep .v-alert__wrapper {
  justify-content: center;
}

.product-mapper-container
  > .conflict-alert::v-deep
  .v-alert__wrapper
  > .v-alert__content {
  flex: 0 1 auto;
}

.rule-container {
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0px;
  background-color: white;
  height: 40%;
  overflow-y: scroll;
  padding: 0;
  z-index: 10;
}

.rule-toolbar {
  padding: 0;
  margin-bottom: 5px;
  display: flex;
}

.rule-toolbar .currency-input {
  max-width: 90px;
  margin-left: 6px;
}

.rule-toolbar .date-picker {
  max-width: 140px;
}

.product-limit-select {
  max-width: 70px;
  margin-right: 5px;
}

.rule-table-container,
.rule-json-container {
  height: calc(100% - 69px);
  overflow: scroll;
  padding: 0px 12px 0 12px;
}

.rule-json-container {
  text-align: left;
  padding: 0;
}

.rule-json-container::v-deep > .CodeMirror {
  height: 100%;
}

.rule-table-container::v-deep > .CodeMirror {
  display: none;
}

.rule-table,
.conflict-alert {
  width: 100%;
}

.rule-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 3px;
}

.rule-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > .add-rule-btn {
  width: 100%;
}

.rule-table td > .skip-rule-checkbox {
  margin: 0;
  padding: 0;
}

.rule-button-container {
  display: flex;
  align-items: baseline;
}

.output-variation-attribute-header:hover,
.input-variation-attribute-header:hover {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.product-mapping-table-toolbar {
  border-bottom: 1px solid lightgray;
}

.product-mapping-table-container.conflict {
  height: calc(60% - 72px);
}

.rule-button-container > .v-input--checkbox {
  margin: 0px 5px 0px 5px;
}

.filler-header {
  width: 20px;
}

.help-card {
  text-align: left;
}

.selected {
  background-color: var(--v-psblue-base);
  color: white;
}

.products::v-deep .v-data-table-header > tr > th {
  white-space: nowrap;
}

.products tr.product > td {
  text-align: center;
}

.products tr.product:not(.skip) > td.rule-match > span {
  background-color: yellow;
  color: black;
}

.products tr.product > td.mapper-arrow-column > div.mapper-arrow-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.products
  tr.product
  > td.output-variation-attribute::v-deep
  > div.combo-box-container {
  margin: 4px 0 4px;
  max-width: 150px;
}

.products tr.product > td.output-variation-attribute > span.mapped-attribute {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 32px;
}

.products
  tr.product
  > td.output-variation-attribute
  > span.mapped-attribute
  > .rule-name {
  margin-right: 6px;
}

.product.selected:hover {
  color: black !important;
}

.product.skip td {
  background-color: lightgray !important;
  color: black;
}

.product.skip td.mapper-arrow-column > div > .rule-name,
.product.skip td.input-variation-attribute,
.product.skip td.output-variation-attribute > .mapped-attribute {
  text-decoration: line-through;
}

tr.product.conflict {
  background-color: #f44336 !important;
}

th.grouped-by {
  background-color: #eeeeee;
}

.price-data-card {
  padding-bottom: 12px;
}
</style>
