export default {

    methods: {

        match(string, expression) {

            try {
                //This method copies the behaviour of the String.matches method in Java
                //this is needed because the connectors are written in Java
                if (!expression.startsWith("^")) expression = "^" + expression;
                if (!expression.endsWith("$")) expression = expression + "$";
                return string.match(expression);
            } catch (e) {
                console.warn(e);
            }
            return false;
        },

        matchExpression(expression, subject) {
            if (expression === null) return true;
            if (expression === '*') expression = '.*';
            if (expression === '') return false;
            return this.match(subject, expression) || subject.includes(expression);
        },

        checkRules(product, rules, onlyFullResult) {
            //MUST BE in sync with checkRules method of connectors
            if (onlyFullResult === undefined) onlyFullResult = true;

            let ruleResponse = {
                match: false,
                full: false,
                skip: false,
                attributes: {},
                rules: {},
                matches: {}
            };

            let full = false;

            rules.forEach(rule => {
                let match = true;

                //check if the input rules expression does match with a variation attribute
                for (let key in rule.in) {
                    let expression = rule.in[key];
                    if (!this.matchExpression(expression, product.variationAttributes[key])) {
                        match = false;
                    } else {
                        //Only needed for product mapper UI to highlight attributes which match the currently selected rule
                        if (rule.single) continue;
                        if (!ruleResponse.matches[key]) ruleResponse.matches[key] = [];
                        ruleResponse.matches[key].push(rule.name);
                    }
                }

                //if the expression does not match, skip the rule
                if (!match) return;

                full = true;
                if (rule._selected) ruleResponse.match = true;

                if (rule.skip) {
                    ruleResponse.skip = true;
                    ruleResponse.skip_rule = rule;
                }

                for (let key in rule.out) {
                    let expression = rule.out[key]
                    if (!expression) continue;
                    ruleResponse.rules[key + "_rule"] = rule;

                    if (expression.startsWith("$")) {
                        let attr = expression.substring(1);
                        let oriValue = product.variationAttributes[attr];
                        if (oriValue) ruleResponse.attributes[key] = oriValue;
                    }
                    else {
                        ruleResponse.attributes[key] = expression;
                    }
                }
            });

            //Check if some attributes are empty.
            for (let index in ruleResponse.attributes) {
                if (!ruleResponse.attributes?.[index]) {
                    //attribute is empty, so set full flag to false and exit the loop
                    full = false;
                    break;
                }
            }

            ruleResponse.full = full;
            if (onlyFullResult && (ruleResponse.skip || !ruleResponse.full)) return null;
            return ruleResponse;
        }
    }
}