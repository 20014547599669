<template>
  <v-select
    v-model="importCatalog"
    dense
    hide-details="auto"
    outlined
    item-text="name"
    item-value="id"
    label="Import Catalog"
    data-test-id="importCatalogSelector"
    ref="importCatalogSelect"
    :items="catalogItems"
    :rules="catalogRules"
    :loading="runningAction"
    :disabled="runningAction"
  >
    <template #prepend-item>
      <v-list-item>
        <v-spacer />
        <v-dialog v-model="upsertCatalog" width="600">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              class="add-domain-btn"
              data-test-id="addImportCatalogBtn"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <Catalog
            :key="upsertCatalog"
            :allowed-types="['IMPORT']"
            @close="closeDialog(false)"
            @create="closeDialog"
            data-test-id="сreateCatalogDialog"
          />
        </v-dialog>
        <v-btn
          icon
          :loading="runningAction"
          :disabled="runningAction"
          data-test-id="reloadImportCatalogsBtn"
          @click.stop.prevent="loadImportCatalogs"
        >
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
import Catalog from "../../../catalog/Catalog";

export default {
  components: {
    Catalog,
  },

  inject: {
    getAllSpecs: {
      default() {
        console.warn("Parent does not provide getAllSpecs function");
        return [];
      },
    },
  },

  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },

    importConfiguration: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      importCatalog: this.value,
      catalogs: [],
      runningAction: false,
      upsertCatalog: false,
    };
  },

  mounted() {
    this.loadImportCatalogs();
  },

  watch: {
    importCatalog(catalogId) {
      this.$emit("input", catalogId);
    },
  },

  methods: {
    async loadImportCatalogs() {
      this.runningAction = true;
      try {
        //load all import catalogs from this domain
        const catalogs = await this.$store.$coreApi.coreCatalogApi.getCatalogs(
          this.selectedDomain
        );

        this.catalogs = catalogs.filter((catalog) => catalog.type === "IMPORT");
        this.$emit("catalogs:loaded", this.catalogs);
      } finally {
        this.runningAction = false;
      }
    },

    async closeDialog(catalog) {
      this.upsertCatalog = false;
      if (catalog) {
        await this.loadImportCatalogs();
        //select the currently created catalog and close the select list
        this.importCatalog = catalog.id;
        this.$nextTick(() => {
          const el = this.$refs.importCatalogSelect;
          if (el) el.blur();
        });
      }
    },
  },
  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    catalogItems() {
      if (this.importCatalog && this.catalogs.length === 0) {
        //Make sure the saved catalog is displayed even if there are no catalogs
        return [
          {
            id: this.importCatalog,
            name: this.importCatalog,
          },
        ];
      }
      return this.catalogs;
    },

    catalogRules() {
      return [
        (id) => !!id || "Import catalog is required",
        (id) => {
          const specs = this.getAllSpecs();
          //check if import catalog id is already used
          const isUnique = !specs?.some((spec) => {
            return !!spec?.configuration?.importConfigurations?.some(
              (conf) =>
                conf.id !== this.importConfiguration.id &&
                conf.importCatalogId === id
            );
          });
          return (
            isUnique ||
            "Selected catalog is already used by a different import configuration"
          );
        },
      ];
    },
  },
};
</script>

<style scoped>
</style>