<template>
  <DetailView
    v-model="selectedTab"
    class="spec-editor-container"
    :title="spec.id"
    :loading="runningAction"
    @close="$emit('close', false)"
    @resize="onResize"
  >
    <template #actions="{ loading }">
      <v-btn
        v-if="editEnabled"
        color="green"
        data-test-id="saveSpecBtn"
        :disabled="loading"
        @click="saveSpec(false)"
      >
        Save
      </v-btn>
      <v-btn
        v-if="editEnabled"
        color="red"
        data-test-id="deleteSpecBtn"
        :disabled="loading"
        @click="deleteSpec"
      >
        Delete
      </v-btn>
      <v-btn
        color="primary"
        data-test-id="specDetailEditBtn"
        :disabled="loading"
        @click="!editEnabled ? loadSpec() : (editEnabled = false)"
      >
        {{ editEnabled ? "Cancel" : "Edit" }}
      </v-btn>
    </template>

    <template #tabs="{ loading }">
      <v-tabs grow ref="tabs" :value="selectedTab" @change="changeTab">
        <v-tab
          data-test-id="specOverviewTab"
          ref="overviewTab"
          :disabled="loading"
        >
          <v-icon
            v-if="editEnabled && !isConfigValid"
            left
            class="error-alert"
            color="red"
          >
            mdi-alert
          </v-icon>
          Overview
        </v-tab>
        <v-tab
          data-test-id="specImportConfigurationsTab"
          ref="importConfigTab"
          :disabled="loading"
        >
          Import Configurations
        </v-tab>
      </v-tabs>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tabs.content>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item data-test-id="specOverview">
          <div data-test-id="specOverview" class="spec-fields">
            <v-list>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon>mdi-identifier</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title data-test-id="specId">
                    {{ spec.id }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Spec-ID</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon>mdi-connection</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title data-test-id="specConnectorId">
                    {{ spec.connectorId }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Connector</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-hand-coin</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ spec.serviceProviderId }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Service provider</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <div
            class="spec-subscriptions"
            outlined
            data-test-id="specSubscriptionsCard"
          >
            <v-form ref="subscriptionForm">
              <v-toolbar flat>
                <v-toolbar-title>Subscriptions</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="editEnabled"
                  text
                  color="primary"
                  data-test-id="addSubscriptionBtn"
                  @click="addSubscription"
                >
                  <v-icon>mdi-plus</v-icon>
                  <div>Add Subscription</div>
                </v-btn>
              </v-toolbar>
              <v-container fluid>
                <div
                  v-if="!spec.subscriptions || spec.subscriptions.length === 0"
                  class="d-flex v-card__text text--secondary justify-center"
                >
                  No subscriptions found
                </div>
                <v-row
                  v-else
                  v-for="(subscription, index) in spec.subscriptions"
                  :key="index"
                  :data-test-id="'subscription_row_' + index"
                >
                  <v-col cols="2">
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      v-model="subscription.priority"
                      label="Priority"
                      :disabled="!editEnabled"
                      :data-test-id="'subscription_priority_' + index"
                      :rules="[
                        (val) => !!val || val === 0 || 'Priority is required',
                      ]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      outlined
                      dense
                      hide-details="auto"
                      v-model="subscription.method"
                      :items="connectorMethods"
                      label="Method"
                      :disabled="!editEnabled"
                      :data-test-id="'subscription_method_' + index"
                      :rules="[(val) => !!val || 'Method is required']"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      v-model="subscription.skuPattern"
                      label="SKU Pattern"
                      :disabled="!editEnabled"
                      :data-test-id="'subscription_sku_pattern_' + index"
                      :rules="[(val) => !!val || 'Pattern is required']"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      icon
                      color="red"
                      @click="spec.subscriptions.splice(index, 1)"
                      :disabled="!editEnabled"
                      :data-test-id="'subscription_' + index + '_delete_btn'"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
          <v-container
            fluid
            class="spec-plugin-comp"
            v-if="editEnabled && connectorUI"
            data-test-id="connectorUI"
          >
            <component
              v-if="connectorUI"
              ref="connectorUI"
              :key="spec.id"
              :is="connectorUI"
              :spec="spec"
              :core-domain="$coreDomain"
              @configured="configureSpec"
              @error="(msg) => $store.commit('SET_ERROR', msg)"
            >
            </component>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <div
            class="d-flex flex-column"
            :key="reloadKey"
            data-test-id="importConfigurationsOverview"
          >
            <v-toolbar v-if="editEnabled" flat>
              <v-spacer></v-spacer>
              <v-btn
                v-if="editEnabled && importConfigurations.length > 0"
                outlined
                :color="errors.length > 0 ? 'red' : 'green'"
                data-test-id="saveAllImportConfigurationsBtn"
                @click="saveAllImportConfigurations"
              >
                <v-tooltip
                  bottom
                  v-if="errors.length > 0"
                  v-model="showErrors"
                  allow-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      left
                      v-bind="attrs"
                      v-on="on"
                      class="error-alert"
                      color="red"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <ol data-test-id="validationErrorsList">
                    <li v-for="(error, index) in errors" :key="index">
                      <dl v-if="error.cause">
                        <dt>{{ error.message }}</dt>
                        <dd
                          v-for="(cause, index) in error.cause"
                          :key="index"
                          class="error-cause"
                        >
                          {{ "\u00a0\u00a0\u00a0\u00a0" + cause }}
                        </dd>
                      </dl>
                      <span v-else>{{ error.message }}</span>
                    </li>
                  </ol>
                </v-tooltip>
                SAVE ALL
              </v-btn>
              <v-btn
                v-if="editEnabled"
                outlined
                color="primary"
                class="ml-2"
                data-test-id="addImportConfigurationsBtn"
                @click="addImportConfiguration"
              >
                <v-icon left>mdi-plus</v-icon>
                <div>Add Configuration</div>
              </v-btn>
            </v-toolbar>

            <div
              v-if="importConfigurations.length === 0"
              class="d-flex justify-center pt-3"
            >
              <div class="d-flex v-card__text text--secondary justify-center">
                No import configurations found
              </div>
            </div>

            <ImportConfiguration
              v-else
              v-for="(config, index) in importConfigurations"
              :import-configuration="config"
              :spec="spec"
              :key="reloadKey + index + config.id + spec.id"
              :data-test-id="'import_configuration_' + config.id"
              :disabled="!editEnabled"
              :ref="config.id"
              class="ma-3"
              @deleted="removeImportConfiguration"
              @saved="$emit('reload', true)"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </DetailView>
</template>

<script>
import DetailView from "components/common/templates/DetailView";
import StatusChip from "components/common/display-helpers/StatusChip";
import ImportConfiguration from "./import-configuration/ImportConfiguration";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  components: {
    DetailView,
    StatusChip,
    ImportConfiguration,
  },

  data() {
    return {
      spec: this.$cloneObject(this.value) ?? {},
      editEnabled: false,
      catalogs: [],
      inputProductVariations: null,
      editMapping: false,
      connectorUI: null,
      isConfigValid: false,
      specConfigured: false,
      runningAction: false,
      connectorMethods: [],
      selectedTab: 0,
      errors: [],
      showErrors: false,
      reloadKey: 0,
    };
  },

  mounted() {
    if (!this.editEnabled) {
      this.catalogs = this.spec.importCatalogId
        ? [this.spec.importCatalogId]
        : [];
      if (this.spec.subscriptions)
        this.spec.subscriptions.forEach((subscription) =>
          this.connectorMethods.push(subscription.method)
        );
    }
  },

  watch: {
    "value.id": function () {
      this.spec = this.$cloneObject(this.value);
    },

    showPreload(isShown) {
      if (isShown) this.getPreloadStatus();
    },

    spec: {
      handler: function (spec) {
        this.$emit("input", spec);
      },
      deep: true,
    },
  },

  methods: {
    onResize() {
      this.$refs.tabs?.onResize();
    },

    async loadSpec() {
      this.runningAction = true;
      try {
        const spec = await this.$store.$coreApi.coreConnectorApi.getSpec(
          this.selectedDomain,
          this.spec.id
        );

        if (!spec?.id) {
          this.runningAction = false;
          return;
        }
        this.spec = spec;
        await this.loadConnectorCapabilities();
        await this.loadConnectorUI();
        this.editEnabled = true;
        this.reloadKey++;
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Something went wrong when loading spec " +
            this.spec.id +
            "! Cause: " +
            e
        );
      } finally {
        this.runningAction = false;
      }
    },

    addSubscription() {
      if (!this.spec.subscriptions) this.$set(this.spec, "subscriptions", []); //create an new reactive array
      this.spec.subscriptions.push({
        priority: null,
        method: null,
        skuPattern: null,
      });
    },

    validate() {
      const subscriptionForm = this.$refs.subscriptionForm;
      const connectorUI = this.$refs?.connectorUI?.$el;
      const form = connectorUI?.querySelector("form");

      //$nextTick apperantly does not work with tab changes,
      //so wait a hard coded amount of time before focusing invalid inputs

      if (!subscriptionForm.validate()) {
        this.selectedTab = 0;
        window.setTimeout(() => this.$validateVForm(subscriptionForm), 200);
        return false;
      }

      //Show error in connector UI
      if (form) {
        if (form.checkValidity()) {
          this.isConfigValid = true;
          return true;
        }
        this.selectedTab = 0;
        this.isConfigValid = false;
        const vuetify = this.$vuetify;
        window.setTimeout(() => {
          const input = form.querySelector("input:invalid");
          vuetify.goTo(input);
          form.reportValidity();
        }, 200);
        return false;
      }

      if (!this.isConfigValid) {
        window.setTimeout(() => this.$vuetify.goTo(connectorUI), 200);
        return false;
      }

      return true;
    },

    async saveSpec() {
      if (!this.validate()) return;
      try {
        this.runningAction = true;
        const res = await this.$store.dispatch("put", {
          path: "/spec/" + this.spec.id,
          body: this.spec,
          successMsg: "Spec " + this.spec.id + " updated",
        });

        if (!res?.ok) {
          const response = await res.json();
          throw Error(response.message);
        }

        this.reloadKey++;
        this.$emit("reload", true);
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Something went wrong when runningAction spec " +
            this.spec.id +
            "! Cause: " +
            e
        );
      } finally {
        this.runningAction = false;
      }
    },

    async deleteSpec() {
      if (
        !(await this.$confirm(
          "Delete spec?",
          "Do you really want to delete Specification " + this.spec.id + "?"
        ))
      )
        return;
      try {
        this.runningAction = true;
        const res = await this.$store.dispatch("delete", {
          path: "/spec/" + this.spec.id,
          successMsg: "Spec " + this.spec.id + " deleted",
        });

        if (!res?.ok) {
          const response = await res.json();
          throw Error(response.message);
        }

        this.$emit("deleted");
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Something went wrong when runningAction spec " +
            this.spec.id +
            "! Cause: " +
            e
        );
      } finally {
        this.runningAction = false;
      }
    },

    async loadConnectorCapabilities() {
      //retrieve possible methods for subscription from connector capabilities
      let connector = await this.$store.dispatch("get", {
        absolutePath: "/connector/" + this.spec.connectorId,
      });

      let methods =
        connector?.capabilities?.map((capability) => {
          return capability.method;
        }) ?? [];
      methods.push("*");
      this.connectorMethods = methods;
    },

    async loadConnectorUI() {
      try {
        //load the plugin component from the given connector
        const url =
          this.$coreDomain +
          "/" +
          this.selectedDomain +
          "/spec/" +
          this.spec.id +
          "/ui/index.vue?cb=" +
          Math.random();
        //eslint-disable-next-line
        this.connectorUI = await this.$loadExternalComponent(url);
      } catch (e) {
        this.$store.commit(
          "SET_ERROR",
          "Error: Could not load index.vue of connector. " + e
        );
      }
    },

    configureSpec(isConfigured) {
      if (!isConfigured) {
        this.specConfigured = false;
        this.isConfigValid = false;
        return;
      }

      if (!this.specConfigured) {
        this.$store.commit("SET_SUCCESS", "Spec configured successfully");
        this.specConfigured = true;
      }

      this.isConfigValid = true;
    },

    changeTab(tab) {
      //Validate configuration before switching tab
      if (this.editEnabled && !this.validate()) {
        //if the configuration is invalid, show the overview tab
        this.$nextTick(() => {
          //simulate a click on the "Overview" tab, so that
          //the tab is not changed
          const overviewTab = this.$refs.overviewTab;
          const evt = new MouseEvent("click");
          this.selectedTab = 0;
          overviewTab.click(evt);
        });
        return;
      }
      this.selectedTab = tab;
    },

    async addImportConfiguration() {
      const name = await this.$prompt("Import configuration name", {
        width: 500,
      });
      if (!name) return;
      let configurations = this.spec?.configuration?.importConfigurations ?? [];
      configurations.push({
        name,
        id: this.$uuid.v4(),
        mappingRules: [],
        priceList: [],
        importCatalogId: undefined,
        additionalSettings: {},
        notSaved: true, // <- this flag declares, that the config was not saved yet
      });
      this.$set(
        this.spec.configuration,
        "importConfigurations",
        configurations
      );
    },

    removeImportConfiguration(id) {
      const idx = this.spec.configuration.importConfigurations.findIndex(
        (conf) => conf.id === id
      );
      this.spec?.configuration.importConfigurations.splice(idx, 1);
      this.$emit("reload", true);
    },

    async saveAllImportConfigurations() {
      try {
        this.savingImportConfigurations = true;

        const configurations = this.spec.configuration.importConfigurations;

        //Validate all import configurations by calling the validate function of their
        //components
        const invalid = configurations.some(
          (conf) => !this.$refs[conf.id][0].validate()
        );
        if (invalid) return;

        const res = await this.$store.dispatch("put", {
          path: "/spec/" + this.spec.id + "/importConfigurations",
          body: configurations,
          successMsg:
            "Import configurations of " + this.spec.id + " saved successfully",
          returnErrors: true,
        });

        if (!res?.ok) {
          if (res?.status === 400) {
            const response = await res.json();
            const errors = response.violations;
            if (errors?.length > 0) {
              this.errors = errors.map((error) => {
                return {
                  message: error.property + ": " + error.message,
                };
              }, []);
              this.showErrors = true;
              window.setTimeout(() => (this.showErrors = false), 2000);
            }
          } else {
            const error = await res.json();
            throw Error(error.code + ": " + error.message);
          }
        } else {
          configurations.forEach((conf) => this.$delete(conf, "notSaved"));
          this.showErrors = false;
          this.errors = [];
          this.reloadKey++;
          this.$emit("reload", true);
        }
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
      } finally {
        this.savingImportConfigurations = false;
      }
    },
  },

  computed: {
    importConfigurations() {
      return this.spec?.configuration?.importConfigurations ?? [];
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },
  },
};
</script>

<style scoped>
.spec-editor-container {
  padding: 0px;
  overflow-y: hidden;
  align-items: flex-start;
  align-content: flex-start;
}

.spec-editor {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 5px;
  padding: 0px;
}

.spec-editor > .v-tabs-items {
  width: 100%;
  height: calc(100% - 112px);
  overflow-y: scroll;
}

.spec-fields {
  display: flex;
  flex: 0 1 auto;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 12px;
  margin: 12px;
}

.spec-subscriptions {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 12px;
  padding: 4px;
}

.spec-editor-toolbar button {
  margin: 0px 5px 0px 0px;
}

.v-dialog__content--active {
  background-color: white;
}
</style>