<template>
  <v-container fluid class="regex-editor-container">
    <v-container v-if="!isSimple || skip" fluid class="regex-input-container">
      <DimensionsComboBox
        class="regex-input"
        v-model="model"
        :skip="skip"
        :attribute="attribute"
        data-test-id="regexEditorInput"
      />
      <span
        v-if="(model === '*' || !model) && !skip"
        @click="
          model = '^().*().*()$';
          forceComplex = false;
        "
        class="simple-btn"
        title="Switch to simple regex input"
        data-test-id="regexEditorSimpleBtn"
      >
        <v-icon color="primary" small>mdi-text</v-icon>
      </span>
    </v-container>
    <v-container v-if="isSimple" fluid class="simple-input-container">
      <v-container
        fluid
        class="simple-inputs-group"
        data-test-id="regexEditorSimpleInputGroup"
      >
        <input
          class="simple-input"
          @change="unsplit"
          placeholder="Start"
          v-model="start"
        />
        <input
          class="simple-input"
          @change="unsplit"
          placeholder="Mid"
          v-model="mid"
        />
        <input
          class="simple-input"
          @change="unsplit"
          placeholder="End"
          v-model="end"
        />
      </v-container>
      <span
        @click="forceComplex = true"
        class="regex-btn"
        title="Switch to complex regex input"
        data-test-id="regexEditorComplexBtn"
      >
        <v-icon color="primary" small>mdi-regex</v-icon>
      </span>
    </v-container>
  </v-container>
</template>

<script>
import DimensionsComboBox from "./DimensionsComboBox";

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },

    skip: {
      type: Boolean,
      required: false,
      default: false,
    },

    attribute: {
      type: Object,
      required: true,
    },
  },

  components: {
    DimensionsComboBox,
  },

  data() {
    return {
      model: !this.skip ? this.value : "",
      savedModel: "",
      forceComplex: false,
      start: "",
      mid: "",
      end: "",
      showDataList: false,
    };
  },

  mounted() {
    this.split();
  },

  watch: {
    skip(skip) {
      if (skip) {
        this.savedModel = this.model.slice();
        this.model = "";
      } else {
        this.model = this.savedModel;
      }
    },

    model(model) {
      this.$emit("input", model);
    },

    value(value) {
      if (this.model === value) return;
      this.model = value;
      this.split();
    },
  },

  methods: {
    split() {
      if (this.model === null) return null;
      //eslint-disable-next-line
      const matches = this.model.match(/^\^\(([^\)]*)\)\.\*\(([^\)]*)\)\.\*\(([^\)]*)\)\$$/);
      if (matches === null || matches.length != 4) return null;
      // unescape dots
      this.start = matches[1].replace(/\\\./g, ".");
      this.mid = matches[2].replace(/\\\./g, ".");
      this.end = matches[3].replace(/\\\./g, ".");
    },
    unsplit() {
      // escape dots
      const start = this.start.replace(/\./g, "\\.");
      const mid = this.mid.replace(/\./g, "\\.");
      const end = this.end.replace(/\./g, "\\.");
      this.model = "^(" + start + ").*(" + mid + ").*(" + end + ")$";
    },
  },

  computed: {
    isSimple() {
      this.forceComplex;
      this.model;
      if (this.model === "" || this.model === null || this.forceComplex)
        return false;
      // check if the RE has this structure: ^(one).*(two).*(three)$
      //eslint-disable-next-line
      return !!this.model.match(/^\^\(([^\)]*)\)\.\*\(([^\)]*)\)\.\*\(([^\)]*)\)\$$/);
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0;
}

.regex-editor-container {
  min-width: 150px;
  width: 100%;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.simple-input-container,
.regex-input-container {
  min-width: 150px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
}

.regex-input-container::v-deep .combo-box-container {
  border: none;
}

.regex-input-container:focus-within {
  outline: rgb(25, 118, 210);
}

.regex-input {
  display: flex;
  width: 100%;
}

.regex-input.combo-box-container::v-deep > .combo-box-input:focus-within {
  outline: none;
}

.simple-input {
  width: 60px;
  caret-color: auto;
  min-height: 26px;
  line-height: 20px;
  padding: 0 12px;
  font-size: 16px;
}

.simple-inputs-group {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  margin: 0;
}

.regex-btn,
.simple-btn {
  display: flex;
}
</style>