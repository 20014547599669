<template>
  <div
    fluid
    ref="comboBox"
    :class="{
      'combo-box-container': true,
      'has-error': hasError,
    }"
  >
    <!-- 
      Since the combobox does only update the model when it loses focus,
      a custom change handler has been implemented.
      Code taken from: https://github.com/vuetifyjs/vuetify/issues/4679#issuecomment-570924187
    -->
    <v-combobox
      v-model="dummyModel"
      solo
      dense
      flat
      hide-details
      ref="combobox"
      data-test-id="dimensionComboBoxInput"
      :class="{
        'combo-box-input': true,
        'error--text': hasError,
      }"
      :id="comboboxId"
      :items="items"
      :disabled="skip"
      :readonly="skip"
      :rules="inputRules"
      @focus="$emit('focus')"
      @change="onAutoCompleteSelection"
      @keyup="customOnChangeHandler"
      @paste="customOnChangeHandler"
      @update:error="checkForError"
      @blur="checkForError"
      @click.stop.prevent
    >
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-content>
            <v-list-item-title
              :data-test-id="attribute.key + '_menu_item_' + item"
              >{{ item }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>

    <v-tooltip
      v-if="hasError"
      bottom
      allow-overflow
      :activator="'#' + comboboxId"
    >
      <div>{{ error }}</div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },

    attribute: {
      type: Object,
      required: true,
    },

    skip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      model: this.value,
      dummyModel: this.value,
      error: null,
      hasError: false,
    };
  },

  mounted() {
    this.$nextTick(() => this.checkForError());
  },

  watch: {
    value(value) {
      if (this.model === value) return;
      this.model = value;
    },

    model(model) {
      this.$emit("input", model);
      this.dummyModel = model;
      this.checkForError();
    },
  },

  methods: {
    onAutoCompleteSelection() {
      this.model = this.dummyModel;
    },

    customOnChangeHandler() {
      this.$nextTick(() => {
        if (this.$refs.combobox) {
          this.model = this.$refs.combobox.internalSearch;
        }
      });
    },

    checkForError() {
      const input = this.$refs.combobox;
      input?.validate();
      this.$nextTick(() => {
        const hasError = !!input?.errorBucket?.length > 0;
        this.hasError = hasError;
        if (hasError) {
          this.error = input.errorBucket?.[0];
          return;
        }
        this.error = null;
      });
    },
  },

  computed: {
    items() {
      const optionEnumValues = this.attribute?.optionEnumValues;
      if (!optionEnumValues) return [];
      return optionEnumValues.map((option) => {
        return option?.code || option;
      });
    },

    inputRules() {
      let rules = [];
      const codePattern = this.attribute?.codePattern;
      if (codePattern) {
        rules.push((val) => {
          if (!val || val.startsWith("$")) return true;
          const regex = new RegExp(codePattern);
          return regex.test(val) || "Code must match pattern " + codePattern;
        });
      }

      return rules;
    },

    comboboxId() {
      return "combobox_" + this._uid;
    },
  },
};
</script>

<style scoped>
.combo-box-container {
  display: inline-flex;
  padding: 0;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.combo-box-container.has-error {
  border-color: var(--v-error-base) !important;
}

.combo-box-container:focus-within {
  border-color: var(--v-primary-base);
  height: auto;
  overflow: visible;
  background-color: white;
}

.combo-box-input.v-text-field.v-text-field--solo.v-input--dense::v-deep
  > .v-input__control {
  min-height: 24px;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 20px;
}

.combo-box-input::v-deep .v-input__icon > .v-icon {
  font-size: 16px;
}

.combo-box-input::v-deep .v-input__icon {
  width: 16px;
  height: 16px;
}

.combo-box-input::v-deep .v-input__append-inner {
  width: 18px;
}
</style>
