var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"comboBox",class:{
    'combo-box-container': true,
    'has-error': _vm.hasError,
  },attrs:{"fluid":""}},[_c('v-combobox',{ref:"combobox",class:{
      'combo-box-input': true,
      'error--text': _vm.hasError,
    },attrs:{"solo":"","dense":"","flat":"","hide-details":"","data-test-id":"dimensionComboBoxInput","id":_vm.comboboxId,"items":_vm.items,"disabled":_vm.skip,"readonly":_vm.skip,"rules":_vm.inputRules},on:{"focus":function($event){return _vm.$emit('focus')},"change":_vm.onAutoCompleteSelection,"keyup":_vm.customOnChangeHandler,"paste":_vm.customOnChangeHandler,"update:error":_vm.checkForError,"blur":_vm.checkForError,"click":function($event){$event.stopPropagation();$event.preventDefault();}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"data-test-id":_vm.attribute.key + '_menu_item_' + item}},[_vm._v(_vm._s(item))])],1)],1)]}}]),model:{value:(_vm.dummyModel),callback:function ($$v) {_vm.dummyModel=$$v},expression:"dummyModel"}}),(_vm.hasError)?_c('v-tooltip',{attrs:{"bottom":"","allow-overflow":"","activator":'#' + _vm.comboboxId}},[_c('div',[_vm._v(_vm._s(_vm.error))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }