<template>
  <div class="import-configuration d-flex flex-column elevation-4">
    <v-form ref="importConfigurationForm">
      <!-- HEADER -->
      <div :class="{
        'import-configuration-header': true,
        disabled: disabled,
      }">
        <div class="d-flex mr-3">
          <v-btn
            v-if="!disabled"
            icon
            data-test-id="expandImportConfigurationBtn"
            :disabled="loading"
            :loading="loading"
            @click="expand"
          >
            <v-icon v-if="!isExpanded">mdi-chevron-right</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
        <div class="import-configuration-name">
          <div
            v-if="!editName"
            :title="importConfiguration.name"
            class="text-h6"
            data-test-id="importConfigurationName"
            @dblclick="editName = true"
          >
            {{ importConfiguration.name }}
          </div>
          <v-text-field
            v-else
            v-model="importConfiguration.name"
            outlined
            dense
            hide-details="auto"
            data-test-id="importConfigurationNameInput"
            :rules="[(val) => !!val || 'Name is required']"
            @keydown.enter.prevent="toggleNameEdit"
          />
        </div>

        <v-btn
          v-if="!disabled"
          icon
          class="ml-2"
          data-test-id="editNameBtn"
          :color="editName ? 'green' : 'grey'"
          @click="toggleNameEdit"
        >
          <v-icon v-if="!editName">mdi-pencil</v-icon>
          <v-icon v-else>mdi-check</v-icon>
        </v-btn>

        <v-divider
          vertical
          class="mx-3"
        />
        <div
          v-if="!isExpanded"
          class="d-flex flex-row"
        >
          <div class="d-flex flex-column justify-space-around">
            <div class="d-flex flex-row align-center">
              <div class="font-weight-bold mr-1">Catalog:</div>
              <div
                v-if="importConfiguration.importCatalogId"
                class="import-catalog-id"
              >
                <a
                  data-test-id="importCatalog"
                  :title="importCatalogName"
                  @click="
                    $router.push({
                      name: 'catalogDetail',
                      params: { catalog: importConfiguration.importCatalogId },
                    })
                  "
                >
                  {{ importCatalogName }}
                </a>
              </div>
              <div
                v-else
                data-test-id="noCatalogMsg"
                class="text--undefined"
              >
                No import catalog selected
              </div>
            </div>
            <div class="d-flex flex-row align-center">
              <div class="font-weight-bold mr-2">Preload status:</div>
              <div class="d-flex align-center">
                <StatusChip
                  v-if="preload.lastJob"
                  :status="preload.lastJob.status"
                  :loading="loadingStatus"
                  :disabled="disabled"
                  small
                  class="preload-status"
                  data-test-id="lastPreloadStatus"
                />
                <div
                  v-else
                  class="text--undefined"
                >No products loaded yet</div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column justify-space-around ml-3">
            <div class="d-flex flex-row align-center">
              <div class="font-weight-bold mr-2">From:</div>
              <div
                v-if="priceListSpan.fromDate"
                data-test-id="importConfigurationFromDate"
              >
                {{
                  $getLocalizedDate(priceListSpan.fromDate, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                }}
              </div>
              <div
                v-else
                class="text--undefined"
              >
                No price list defined yet
              </div>
            </div>
            <div class="d-flex flex-row align-center">
              <div class="font-weight-bold mr-2">Until:</div>
              <div
                v-if="priceListSpan.untilDate"
                data-test-id="importConfigurationUntilDate"
              >
                {{
                  $getLocalizedDate(priceListSpan.untilDate, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                }}
              </div>
              <div
                v-else
                class="text--undefined"
              >
                No price list defined yet
              </div>
            </div>
          </div>
          <v-divider
            vertical
            class="mx-3"
          />
          <div
            v-if="lastImportJob"
            class="d-flex flex-column justify-space-between"
          >
            <div class="d-flex flex-row align-center">
              <div class="font-weight-bold mr-2">
                {{
                  lastImportJob.state === "INIT" ||
                  lastImportJob.state === "NEW" ||
                  lastImportJob.state === "RUNNING"
                  ? "Current"
                  : "Last"
                }}
                Import:
              </div>
              <div>
                <StatusChip
                  :small="true"
                  :status="lastImportJob.state"
                  :loading="loadingImportJobs"
                  :disabled="disabled"
                  :progress="lastImportJob.progress"
                  append-icon="mdi-reload"
                  @click="loadImportJobs"
                />
              </div>
            </div>
            <div class="d-flex flex-row align-center">
              <div class="font-weight-bold mr-2">Started at:</div>
              <div>{{ $getLocalizedDate(lastImportJob.startedAt) }}</div>
            </div>
          </div>
        </div>
        <v-spacer />
        <div class="d-flex flex-row">
          <v-tooltip
            bottom
            v-if="errors.length > 0"
            v-model="showErrors"
            allow-overflow
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                left
                v-bind="attrs"
                v-on="on"
                class="error-alert"
                color="red"
              >
                mdi-alert
              </v-icon>
            </template>
            <ol data-test-id="validationErrorsList">
              <li
                v-for="(error, index) in errors"
                :key="index"
              >
                <dl v-if="error.cause">
                  <dt>{{ error.message }}</dt>
                  <dd
                    v-for="(cause, index) in error.cause"
                    :key="index"
                    class="error-cause"
                  >
                    {{ "\u00a0\u00a0\u00a0\u00a0" + cause }}
                  </dd>
                </dl>
                <span v-else>{{ error.message }}</span>
              </li>
            </ol>
          </v-tooltip>
          <v-btn
            v-if="!disabled && importConfiguration.importCatalogId"
            color="green"
            data-test-id="importConfigurationSaveBtn"
            :disabled="saving"
            :loading="saving"
            :text="!isDirty"
            @click="saveImportConfiguration"
          >
            SAVE
          </v-btn>

          <v-btn
            v-if="!disabled"
            text
            data-test-id="importConfigurationDeleteBtn"
            color="red"
            class="ml-1"
            :disabled="deleting"
            :loading="deleting"
            @click="deleteImportConfiguration"
          >
            DELETE
          </v-btn>
        </div>
      </div>

      <!-- BODY -->
      <v-expand-transition>
        <div
          v-show="isExpanded"
          data-test-id="importConfigurationDetail"
        >
          <div class="d-flex flex-column pa-3">
            <!-- IMPORT CATALOG -->
            <ImportCatalogSelect
              v-model="importConfiguration.importCatalogId"
              :import-configuration="importConfiguration"
              :key="isExpanded"
              @catalogs:loaded="catalogs = $event"
            />

            <!-- PRICE LIST -->
            <div class="price-list-container">
              <v-toolbar flat>
                <v-toolbar-title>Price list</v-toolbar-title>
              </v-toolbar>
              <PriceList
                v-model="importConfiguration.priceList"
                class="price-list"
                ref="priceList"
                :show-import="showImport"
                :disable-import="disableImport"
                @import:season="startImport"
              />
            </div>

            <!-- importSettings.vue -->
            <div
              v-if="loadingSettingsUI"
              class="d-flex flex-grow-1 justify-center align-center"
            >
              <v-progress-circular
                color="grey"
                :size="50"
                indeterminate
              ></v-progress-circular>
            </div>
            <component
              v-else-if="importSettingsUI"
              data-test-id="importSettings"
              class="d-flex my-1"
              ref="additionalSettings"
              :is="importSettingsUI"
              :spec="spec"
              :import-configuration="importConfiguration"
              :core-domain="$coreDomain"
              :access-token="accessToken"
              @error="(msg) => $store.commit('SET_ERROR', msg)"
              @configured="
                (isConfigured) => (settingsConfigured = isConfigured)
              "
            />

            <!-- PRELOAD AND MAPPING EDITOR -->
            <div
              v-if="showPreload"
              class="preload-container"
            >
              <v-toolbar flat>
                <v-toolbar-title>Product Mapping</v-toolbar-title>
              </v-toolbar>
              <v-container
                fluid
                data-test-id="preloadContainer"
              >
                <v-row no-gutters>
                  <v-col
                    cols="2"
                    class="font-weight-bold"
                  >
                    Last successful preload:
                  </v-col>
                  <v-col>
                    <span
                      v-if="preload.lastSuccessful"
                      data-test-id="lastSuccessfulPreload"
                    >
                      {{ $getLocalizedDate(preload.lastSuccessful) }}
                    </span>
                    <span
                      v-else
                      data-test-id="noSuccessfulPreloadMsg"
                      class="text--undefined"
                    >
                      No products loaded yet
                    </span>
                  </v-col>
                </v-row>
                <v-divider
                  class="my-3"
                  v-if="
                    preload.lastJob &&
                    preload.lastJob.status != 'PRELOAD_PENDING'
                  "
                />
                <v-row v-if="
                  preload.lastJob &&
                  preload.lastJob.status != 'PRELOAD_PENDING'
                ">
                  <v-col
                    cols="2"
                    class="font-weight-bold"
                  >
                    Last preload:
                  </v-col>
                  <v-col class="pa-0">
                    <v-container
                      fluid
                      class="px-0 py-3 last-preload-job"
                    >
                      <v-row no-gutters>
                        <v-col cols="1">Status:</v-col>
                        <v-col>
                          <StatusChip
                            v-if="preload.lastJob.status"
                            :status="preload.lastJob.status"
                            class="preload-status"
                            data-test-id="lastPreloadStatus"
                          />
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="1">Loaded products:</v-col><v-col> {{ preload.lastJob.tariffCount }} </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="1">Started:</v-col><v-col>
                          {{ $getLocalizedDate(preload.lastJob.startedAt) }}
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="1">Finished:</v-col>
                        <v-col>{{
                          $getLocalizedDate(preload.lastJob.finishedAt)
                        }}</v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-divider class="my-3" />
                <v-row>
                  <v-col
                    cols="2"
                    class="font-weight-bold"
                  >
                    Current status:
                  </v-col>
                  <v-col>
                    <div
                      v-if="preload.lastStartedAt"
                      class="running-preload-status"
                    >
                      <div class="d-flex flex-row mb-2">
                        <StatusChip
                          v-if="preload.currentStatus"
                          class="preload-status"
                          append-icon="mdi-reload"
                          data-test-id="preloadStatus"
                          :status="preload.currentStatus"
                          :loading="loadingStatus"
                          :progress="preload.completionPercentage"
                          @click="getPreloadStatus"
                        />
                        <div class="mx-2 d-flex align-center">
                          (started at
                          {{ $getLocalizedDate(preload.lastStartedAt) }})
                        </div>
                      </div>
                      <div>
                        <v-checkbox
                          v-model="refreshAutomatically"
                          hide-details
                          label="Automatic refresh"
                          class="pt-0 mt-0"
                          data-test-id="automaticRefreshCheckbox"
                        />
                      </div>
                    </div>
                    <div
                      v-else
                      data-test-id="noPreloadMsg"
                      class="text--undefined"
                    >
                      No preload running
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      text
                      outlined
                      data-test-id="preloadingBtn"
                      :disabled="
                        loadingVariations || loadingMapping || loadingImport
                      "
                      :loading="preloadRunning"
                      @click="
                        triggerPreloading(
                          preload.currentStatus === 'PRELOAD_PENDING'
                        )
                      "
                    >
                      <v-icon left>mdi-cloud-download</v-icon>
                      {{
                        preload.currentStatus === "PRELOAD_PENDING"
                        ? "Save and force reload"
                        : "Save and load products"
                      }}
                    </v-btn>

                    <v-btn
                      v-if="hasProducts"
                      text
                      outlined
                      class="my-1 ml-1"
                      data-test-id="editMappingBtn"
                      :disabled="
                        loadingVariations || loadingMapping || loadingImport
                      "
                      :loading="loadingVariations"
                      @click="loadUiInput"
                    >
                      <v-icon left>mdi-open-in-new</v-icon>
                      OPEN MAPPING EDITOR
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </div>

            <!-- IMPORT JOB LIST -->
            <div
              v-if="showImport"
              class="import-list"
              data-test-id="importList"
            >
              <v-toolbar flat>
                <v-toolbar-title> Import Jobs </v-toolbar-title>
                <v-divider
                  v-if="totalImportJobPages > 0"
                  vertical
                  class="mx-3"
                />
                <PaginationComponent
                  v-if="totalImportJobPages > 0"
                  v-model="importJobPage"
                  :totalPages="totalImportJobPages"
                />
                <v-divider
                  vertical
                  class="mx-3"
                />
                {{ importJobs.length }} import jobs found
                <v-divider
                  vertical
                  class="mx-3"
                />
                <v-btn
                  icon
                  data-test-id="reloadImportJobsBtn"
                  :loading="loadingImportJobs"
                  :disabled="loadingImportJobs || loadingImport"
                  @click="loadImportJobs"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  outlined
                  class="my-1"
                  data-test-id="saveAndImportBtn"
                  :disabled="disableImport"
                  :loading="loadingImport"
                  @click="startImport()"
                >
                  <v-icon left>mdi-file-download-outline</v-icon>
                  SAVE AND START IMPORT
                </v-btn>
              </v-toolbar>
              <v-data-table
                :items="importJobs"
                :headers="importJobHeaders"
                :sort-desc="true"
                :loading="loadingImportJobs"
                :items-per-page="5"
                :page="importJobPage"
                :height="300"
                fixed-header
                sort-by="startedAt"
                hide-default-footer
                item-key="id"
                class="import-job-table"
              >
                <template v-slot:item="{ item }">
                  <tr
                    class="import-job"
                    :data-test-id="'import_job_' + item.id"
                  >
                    <td class="text-start">
                      {{ $getLocalizedDate(item.startedAt) }}
                    </td>
                    <td class="text-start">
                      <a
                        :title="getCatalogName(item.catalogId)"
                        @click="
                          $router.push({
                            name: 'catalogDetail',
                            params: { catalog: item.catalogId },
                          })
                        "
                      >
                        {{ getCatalogName(item.catalogId) }}
                      </a>
                    </td>
                    <td class="text-start">
                      <StatusChip
                        :status="item.state"
                        :progress="item.progress"
                        :data-test-id="'import_status_' + item.id"
                      />
                    </td>
                    <td>
                      <v-icon>
                        {{ item.catalogMerged
                          ? 'mdi-check-circle-outline'
                          : 'mdi-close-circle-outline' }}
                      </v-icon>
                    </td>
                    <td>
                      <v-btn
                        v-if="
                          item.state !== 'CANCELLED' &&
                          item.state !== 'COMPLETE' &&
                          item.state !== 'ERROR'
                        "
                        color="red"
                        text
                        small
                        data-test-id="stopImportBtn"
                        :disabled="cancellingImport || loadingImportJobs"
                        :loading="cancellingImport"
                        @click="cancelImport(item.id)"
                      >
                        <v-icon left>mdi-stop</v-icon>
                        Stop import
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </v-expand-transition>
    </v-form>

    <!-- PRODUCT MAPPER DIALOG -->
    <v-dialog
      v-if="preload.lastSuccessful"
      v-model="editMapping"
      fullscreen
      persistent
    >
      <ProductMapper
        v-if="editMapping"
        v-model="importConfiguration.mappingRules"
        data-test-id="productMapper"
        :spec="spec"
        :import-configuration="importConfiguration"
        :products="inputProductVariations"
        :input-variation-attributes="inputVariationAttributes"
        :output-variation-attributes="outputVariationAttributes"
        :key="editMapping"
        @close="editMapping = false"
        @saved="
          isDirty = false;
        editName = false;
                                      "
      />
    </v-dialog>
  </div>
</template>

<script>
import StatusChip from "components/common/display-helpers/StatusChip";
import ProductMapper from "./product-mapper/ProductMapper";
import PriceList from "./PriceList";
import PaginationComponent from "components/PaginationComponent";
import ImportCatalogSelect from "./ImportCatalogSelect";
import updateToken from "@/assets/js/utilities/keycloak/update-token.js"

export default {
  components: {
    ProductMapper,
    StatusChip,
    PriceList,
    PaginationComponent,
    ImportCatalogSelect,
  },

  props: {
    importConfiguration: {
      type: Object,
      required: true,
    },

    //The spec to which the import configuration belongs
    spec: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },

  data() {
    return {
      importSettingsUI: null,
      savedImportConfiguration: null,
      isExpanded: false,
      preload: {},
      lastPreloadImportConfiguration: null,
      catalogs: [],
      settingsConfigured: false,
      inputVariationAttributes: [],
      outputVariationAttributes: [],
      inputProductVariations: [],
      editMapping: false,
      isDirty: false,
      saving: false,
      deleting: false,
      errors: [],
      showErrors: false,
      editName: false,
      importCatalog: null,

      importJobs: [],
      importJobPage: 1,

      //Loading related variables
      loading: false,
      preloadRunning: false,
      loadingStatus: false,
      loadingPools: false,
      loadingMapping: false,
      loadingImport: false,
      loadingVariations: false,
      loadingSettingsUI: false,
      loadingImportJobs: false,
      cancellingImport: false,

      refreshAutomatically: false,
      refreshInterval: null,

      accessToken: null,
    };
  },

  watch: {
    importConfiguration: {
      handler: function (config) {
        this.isDirty = !this.$isEqual(config, this.savedImportConfiguration);
      },
      deep: true,
    },

    disabled(disabled) {
      if (disabled) {
        this.isExpanded = false;
        this.editName = false;
      }
    },

    "spec.configuration.endpoint": function (endpoint) {
      this.importSettingsUI = null;
      if (endpoint) this.loadImportSettingsUI();
    },

    refreshAutomatically(refresh) {
      if (refresh) {
        this.getPreloadStatus();
        //refreshes the preload status every 5 seconds
        this.refreshInterval = setInterval(() => {
          if (!this.preload.lastStartedAt) {
            clearInterval(this.refreshInterval);
            return;
          }
          this.getPreloadStatus();
        }, 5000);
      } else {
        clearInterval(this.refreshInterval);
      }
    },

    "importConfiguration.importCatalogId": function () {
      this.loadImportCatalog();
    },
  },

  mounted() {
    this.savedImportConfiguration = this.$cloneObject(this.importConfiguration);
    Promise.all([
      this.getPreloadStatus(),
      this.loadImportJobs(),
      this.loadImportCatalog(),
    ]);
  },

  methods: {
    async expand() {
      if (!this.isExpanded) {
        try {
          this.loading = true;
          await this.loadImportSettingsUI(), (this.isExpanded = true);
          this.$nextTick(() => this.validate());
          return;
        } finally {
          this.loading = false;
        }
      }
      this.isExpanded = false;
    },

    async loadImportJobs() {
      try {
        this.loadingImportJobs = true;
        const importJobs =
          await this.$store.$coreApi.coreConnectorApi.getImportJobs(
            this.selectedDomain,
            this.importConfiguration.id
          );
        this.importJobs = importJobs ?? [];
      } finally {
        this.loadingImportJobs = false;
      }
    },

    async loadImportSettingsUI() {
      try {
        this.loadingSettingsUI = true;
        //load the importSettings plugin component from the given connector
        const url =
          this.$coreDomain +
          "/" +
          this.selectedDomain +
          "/spec/" +
          this.spec.id +
          "/ui/importSettings.vue?cb=" +
          Math.random();

        this.accessToken = await updateToken();
        //eslint-disable-next-line
        this.importSettingsUI = await this.$loadExternalComponent(url);
      } catch (e) {
        //only write a warning message in the console, because the core always returns a 503 error
        //and the importSettings.vue could also just not be implemented, which is a valid case too
        console.warn(
          "Warn: importSettings.vue could not be loaded from the connector. " +
          e
        );
      } finally {
        this.loadingSettingsUI = false;
      }
    },

    async loadImportCatalog() {
      const importCatalogId = this.importConfiguration.importCatalogId;
      if (!importCatalogId) return;
      const catalog = await this.$store.$coreApi.coreCatalogApi.getCatalog(
        this.selectedDomain,
        importCatalogId
      );
      this.importCatalog = catalog;
    },

    async triggerPreloading(force) {
      if (!this.validate()) return;
      try {
        this.preloadRunning = true;
        if (
          force &&
          !(await this.$confirm(
            "Force reload?",
            "Do you really want to force a restart of the preload job for configuration " +
            this.importConfiguration.id +
            " ? The currently running job will be terminated."
          ))
        )
          return;

        const saveRes = await this.saveImportConfiguration();
        if (!saveRes?.ok) return;
        await this.$store.$coreApi.coreConnectorApi.startPreload(
          this.selectedDomain,
          this.spec.id,
          {
            importConfigurationId: this.importConfiguration.id,
            spec: this.spec,
          },
          force
        );
        this.refreshAutomatically = false;
        await this.getPreloadStatus();
      } finally {
        this.preloadRunning = false;
      }
    },

    async getPreloadStatus() {
      this.loadingStatus = true;
      try {
        const isJobRunning = this.preload.lastStartedAt;
        const res =
          await this.$store.$coreApi.coreConnectorApi.getPreloadStatus(
            this.selectedDomain,
            this.spec.id,
            {
              importConfigurationId: this.importConfiguration.id,
              spec: this.spec,
            }
          );

        if (res?.ok) {
          const result = await res.json();
          const preloadJobs = result?.preloadStatus;

          const preload = {
            currentStatus: undefined,
            lastSuccessful: undefined,
            lastJob: undefined,
          };

          if (!preloadJobs) {
            this.loadingStatus = false;
            return;
          }

          preloadJobs.forEach((job) => {
            const startedAt = new Date(job.startedAt);
            const finishedAt = job.finishedAt
              ? new Date(job.finishedAt)
              : undefined;

            const lastStartedAt = preload.lastStartedAt
              ? new Date(preload.lastStartedAt)
              : undefined;
            const lastSuccessful = preload.lastSuccessful
              ? new Date(preload.lastSuccessful)
              : undefined;
            const lastJob = preload.lastJob;

            //get the currently running job
            if (
              !job.finishedAt &&
              (!lastStartedAt || startedAt > lastStartedAt)
            ) {
              preload.lastStartedAt = job.startedAt;
              preload.currentStatus = job.status;
              preload.completionPercentage = job.completionPercentage;
            }

            if (
              !lastJob?.finishedAt ||
              new Date(lastJob.finishedAt) < finishedAt
            ) {
              preload.lastJob = Object.assign({}, job);
            }

            //get the last successful preload job
            if (
              job.status === "PRELOAD_COMPLETE" &&
              finishedAt != undefined &&
              (!lastSuccessful || finishedAt > lastSuccessful)
            ) {
              preload.lastSuccessful = job.finishedAt;
            }
          });

          this.preload = preload;

          //if this is a refresh and the running job is completed, show success or error message
          if (isJobRunning && !preload.lastStartedAt) {
            if (preload.lastJob?.status === "PRELOAD_COMPLETE")
              this.$store.commit("SET_SUCCESS", "Products loaded");
            else if (preload.lastJob?.status === "PRELOAD_FAILED")
              this.$store.commit("SET_ERROR", "Loading of products failed");
          }
        }
      } finally {
        this.loadingStatus = false;
      }
    },

    async loadUiInput() {
      if (!this.validate()) return;
      //load input and output dimensions for the product mapper
      try {
        this.loadingVariations = true;
        const response = await this.$store.$coreApi.coreConnectorApi.uiInput(
          this.selectedDomain,
          this.spec.id,
          this.importConfiguration.id
        );

        if (!response?.ok) {
          throw Error("Something went wrong when loading product variations!");
        }

        const input = await response.json();
        const productVariations = input?.productVariations ?? [];

        if (!productVariations || productVariations.length === 0) {
          this.editMapping = false;
          throw Error("No product variations to map!");
        }

        //Use map to guarantee the order of the variation attributes columns
        const inVaAttrsMap = new Map();
        const productType = "SKIPASS";

        //collect all variation attributes from the product variations and add them to the input dimensions
        productVariations.forEach((product) => {
          product.selected = false;
          product.ruleResponse = {};
          const variationAttributes = product.variationAttributes ?? [];
          for (const attribute in variationAttributes) {
            if (!inVaAttrsMap.has(attribute)) {
              inVaAttrsMap.set(attribute, {});
            }

            const inputVarAttribute = inVaAttrsMap.get(attribute);
            const attributeValue = variationAttributes[attribute];
            this.$set(inputVarAttribute, attributeValue, true);
          }
        });

        const inputVarAttrs = Array.from(inVaAttrsMap, ([key, values]) => {
          return {
            key,
            optionEnumValues: Object.keys(values),
          };
        });

        this.inputVariationAttributes = inputVarAttrs;
        this.inputProductVariations = productVariations;

        const dimensions =
          await this.$store.$coreApi.coreConfigurationApi.getProductTypeDimensions(
            this.selectedDomain,
            productType
          );

        if (!dimensions) {
          throw Error(
            "Error: No dimensions for product type " + productType + " found!"
          );
        }
        this.outputVariationAttributes = dimensions;
        this.editMapping = true;
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
      } finally {
        this.loadingVariations = false;
      }
    },

    validate() {
      const priceListForm = this.$refs.priceList.$refs.priceListForm;
      const additionalSettingsForm =
        this.$refs?.additionalSettings?.additionalSettingsForm;
      const importConfigurationForm = this.$refs.importConfigurationForm;

      const validateForm = (form) => {
        if (!form.validate()) {
          this.isExpanded = true;
          this.$nextTick(() => this.$validateVForm(form));
          return false;
        }
        return true;
      };

      if (!this.importConfiguration.name) {
        this.editName = true;
        this.$nextTick(() => validateForm(importConfigurationForm));
        return false;
      }

      if (!validateForm(importConfigurationForm)) return false;
      if (!validateForm(priceListForm)) return false;

      //if the additional settings contains a form also validate it
      if (additionalSettingsForm && !additionalSettingsForm.reportValidity()) {
        this.isExpanded = true;
        this.$vuetify.goTo(additionalSettingsForm);
        return false;
      }

      return true;
    },

    async saveImportConfiguration() {
      try {
        if (!this.validate()) return;
        this.editName = false;
        this.saving = true;

        const res =
          await this.$store.$coreApi.coreConnectorApi.upsertImportConfiguration(
            this.selectedDomain,
            this.spec.id,
            this.importConfiguration
          );

        if (!res?.ok) {
          if (res?.status === 400) {
            const response = await res.json();
            const errors = response.violations;
            if (errors?.length > 0) {
              this.errors = errors.map((error) => {
                return {
                  message: error.property + ": " + error.message,
                };
              }, []);
              this.showErrors = true;
              window.setTimeout(() => (this.showErrors = false), 2000);
            }
          } else {
            try {
              const error = await res.json();
              this.$store.commit(
                "SET_ERROR",
                "Error " + error.code + ": " + error.message
              );
            } catch (e) {
              throw Error(
                "Something unexpected happened while saving import configuration " +
                this.importConfiguration.name
              );
            }
          }
        } else {
          this.$delete(this.importConfiguration, "notSaved");
          this.savedImportConfiguration = this.$cloneObject(
            this.importConfiguration
          );
          this.isDirty = false;
          this.showErrors = false;
          this.errors = [];
          this.$emit("saved");
        }

        return res;
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
      } finally {
        this.saving = false;
      }
    },

    async deleteImportConfiguration() {
      try {
        if (
          !(await this.$confirm(
            "Delete Import Configuration?",
            "Are you sure you want to delete this configuration? It cannot be undone."
          ))
        )
          return;

        this.deleting = true;
        if (!this.importConfiguration.notSaved) {
          const res =
            await this.$store.$coreApi.coreConnectorApi.deleteImportConfiguration(
              this.selectedDomain,
              this.spec.id,
              this.importConfiguration
            );
          if (!res?.ok)
            throw Error(
              "Something went wrong while saving import configuration " +
              this.importConfiguration.name
            );
        }
        this.$emit("deleted", this.importConfiguration.id);
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
      } finally {
        this.deleting = false;
      }
    },

    async startImport({ seasonId, done } = {}) {
      try {
        if (!this.validate()) return;
        this.loadingImport = true;
        const importSpecificSeason = !!seasonId;
        //if a specific season id is given enable only that price list season,
        //else set the enabled flag on all seasons to true
        this.importConfiguration.priceList.forEach((season) => {
          const enabled = importSpecificSeason ? seasonId === season.id : true;
          this.$set(season, "enabled", enabled);
        });
        this.$set(
          this.importConfiguration,
          "mergeCatalog",
          importSpecificSeason
        );
        const saveRes = await this.saveImportConfiguration();
        if (!saveRes?.ok) return;
        const res = await this.$store.$coreApi.coreConnectorApi.startImport(
          this.selectedDomain,
          this.spec.id,
          this.importConfiguration
        );
        if (!res?.ok)
          throw Error(
            "Something went wrong while starting import for configuration " +
            this.importConfiguration.name
          );
        await this.loadImportJobs();
      } catch (e) {
        this.$store.commit("SET_ERROR", e);
      } finally {
        this.loadingImport = false;
        //call the callback function
        if (done) done();
      }
    },

    async cancelImport(id) {
      try {
        if (
          !(await this.$confirm(
            "Stop import?",
            "Do you want to abort the running import?"
          ))
        )
          return;
        this.cancellingImport = true;
        const res = await this.$store.$coreApi.coreConnectorApi.cancelImport(
          this.selectedDomain,
          id
        );

        if (!res?.ok) return;
        await this.loadImportJobs();
      } finally {
        this.cancellingImport = false;
      }
    },

    toggleNameEdit() {
      this.editName = !(this.editName && this.importConfiguration.name);
    },

    getCatalogName(id) {
      if (!this.catalogs) return id;
      return this.catalogs.find((catalog) => catalog.id === id)?.name ?? id;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    importCatalogName() {
      return (
        this.importCatalog?.name ?? this.importConfiguration.importCatalogId
      );
    },

    importJobHeaders() {
      return [
        { text: "Start Date", value: "startedAt" },
        { text: "Catalog", value: "catalogId" },
        { text: "Status", value: "state" },
        { text: "Catalog merged?", value: "catalogMerged" },
        { text: "", value: "running", sortable: false },
      ];
    },

    showPreload() {
      return this.settingsConfigured && (this.hasPriceList || this.hasProducts);
    },

    hasPriceList() {
      const priceList = this.importConfiguration?.priceList;
      return priceList?.length > 0;
    },

    hasProducts() {
      return this.settingsConfigured && this.preload.lastSuccessful;
    },

    showImport() {
      return (
        this.hasProducts &&
        this.importConfiguration.importCatalogId &&
        this.importConfiguration?.mappingRules?.length > 0
      );
    },

    disableImport() {
      const runningStates = ["RUNNING", "INIT", "NEW"];
      return (
        this.loadingImportJobs ||
        this.loadingVariations ||
        this.loadingMapping ||
        this.loadingImport ||
        this.importJobs.some((job) => runningStates.includes(job.state))
      );
    },

    hasMappingRules() {
      return (
        this.hasProducts && this.importConfiguration?.mappingRules?.length > 0
      );
    },

    priceListSpan() {
      //get the whole date span of the price list (earliest fromDate and latest untilDate)
      this.priceListChange;
      const seasons = this.importConfiguration?.priceList;
      let fromDate = undefined;
      let untilDate = undefined;

      if (seasons) {
        seasons.forEach((season) => {
          try {
            //if the fromDate of the season is earlier than the current fromDate, replace it
            if (
              season.fromDate &&
              (!fromDate ||
                this.$getDayDiff(
                  new Date(fromDate),
                  new Date(season.fromDate)
                ) < 0)
            ) {
              fromDate = season.fromDate;
            }

            //if the untilDate of the season is later than the current untilDate, replace it
            if (
              season.untilDate &&
              (!untilDate ||
                this.$getDayDiff(
                  new Date(untilDate),
                  new Date(season.untilDate)
                ) > 0)
            ) {
              untilDate = season.untilDate;
            }
          } catch (e) {
            console.error(e);
            return;
          }
        });
      }

      return {
        fromDate,
        untilDate,
      };
    },

    totalImportJobPages() {
      return Math.ceil(this.importJobs.length / 5);
    },

    lastImportJob() {
      const importJobs = this.$cloneObject(this.importJobs);
      importJobs.sort((job1, job2) => {
        return new Date(job2.startedAt) - new Date(job1.startedAt);
      });
      return importJobs?.[0];
    },
  },
};
</script>

<style scoped>
.import-configuration .import-configuration-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 12px;
  background-color: #fafafa;
  border-bottom: 1px solid lightgrey;
  min-height: 80px;
}

.import-configuration .import-configuration-header>.import-configuration-name {
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.import-configuration .import-configuration-header.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.import-configuration .import-configuration-header.disabled .preload-status {
  opacity: 1;
}

.import-configuration .import-configuration-header .import-catalog-id {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.import-configuration .preload-container,
.import-configuration .price-list-container,
.import-configuration .import-list {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 6px 0;
  padding: 12px;
}

.text--undefined {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}

.import-configuration .preload-container .running-preload-status {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.import-configuration .import-job-table::v-deep>.v-data-table__wrapper>table>tbody>.import-job:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: unset;
}
</style>