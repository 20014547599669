<template>
  <MasterDetail
    class="spec-overview"
    data-test-id="specOverview"
    :detail-open="!!selectedSpec"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar>
      <v-toolbar>
        <div>{{ title }}</div>
        <v-spacer />
        <v-switch
          v-model="groupByConnectors"
          data-test-id="groupByConnectorsSwitch"
          label="Group by Connectors"
          color="primary"
          hide-details
          class="mx-3"
          :disabled="runningAction"
        />
        <v-divider inset vertical class="mx-3" />
        <v-btn
          color="primary"
          data-test-id="newSpecBtn"
          title="Add new spec"
          :disabled="runningAction"
          :fab="showMinified"
          :small="showMinified"
          @click="openSpecDialog"
        >
          <v-icon v-if="showMinified">mdi-plus</v-icon>
          <div v-else>New Spec</div>
        </v-btn>
      </v-toolbar>

      <v-dialog persistent v-model="createSpec" width="500px">
        <Spec
          data-test-id="createSpecDialog"
          :key="createSpec"
          @close="closeDialog"
        />
      </v-dialog>
    </template>
    <template #table>
      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        class="specs-table"
        fixed-header
        single-select
        data-test-id="specTable"
        :group-by="groupBy"
        :items-per-page="-1"
        :height="tableHeight"
        :items="specs"
        :headers="headers"
        :loading="runningAction"
        :item-class="getItemClass"
        @click:row="selectSpec"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:group.header="{ group, isOpen, toggle }">
          <td :colspan="headers.length" :data-test-id="'group_header_' + group">
            <div class="d-flex align-center pa-1">
              <v-icon
                @click="toggle"
                :data-test-id="'group_' + group + '_toogle_btn'"
              >
                {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
              </v-icon>
              <div class="ml-3 font-weight-medium text-truncate">
                {{ group }}
              </div>
            </div>
          </td>
        </template>
      </v-data-table>
    </template>

    <template #detail>
      <SpecDetails
        v-if="selectedSpec && selectedSpec.id"
        :key="selectedSpec.id"
        :value="selectedSpec"
        :style="{
          height: detailHeight + 'px',
        }"
        data-test-id="specDetail"
        @close="closeSpecDetails"
        @deleted="closeSpecDetails(true)"
        @reload="loadSpecs"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "../../common/templates/MasterDetail";
import Spec from "./Spec";
import SpecDetails from "./SpecDetails";
import mainOverviewMixin from "../../../mixins/main-overview-mixin";

export default {
  mixins: [mainOverviewMixin],

  provide() {
    return {
      getAllSpecs: this.getAllSpecs,
    };
  },
  components: {
    MasterDetail,
    Spec,
    SpecDetails,
  },

  data() {
    return {
      specs: [],
      selectedSpec: null,
      runningAction: false,
      createSpec: false,
      groupBy: [],
      groupByConnectors: false,
      tableWidth: 0,
    };
  },

  created() {
    this.init();
  },

  watch: {
    "$route.params": function () {
      if (this.$route.params.spec) this.init();
    },

    groupByConnectors(groupByConnectors) {
      if (groupByConnectors) {
        this.groupBy = ["connectorId"];
      } else {
        this.groupBy = [];
      }
    },
  },

  methods: {
    async init() {
      try {
        const namedRoute = this.$route.name;
        if (
          namedRoute === "specs" ||
          (namedRoute === "specDetail" && !this.selectedSpec)
        ) {
          //load spec if the route is correct or the spec editor is accessed directly via URL
          this.runningAction = true;
          await this.loadSpecs();
        }

        if (namedRoute === "specDetail") {
          //show detail view of requested spec
          const specId = this.$route.params.spec;

          if (!specId) {
            this.closeSpecDetails();
          } else if (!this.selectedSpec || specId != this.selectedSpec.id) {
            const spec = await this.$store.$coreApi.coreConnectorApi.getSpec(
              this.selectedDomain,
              specId
            );

            if (!spec) {
              this.$store.dispatch(
                "setError",
                "No spec with id " + specId + " found!"
              );
              this.closeSpecDetails();
              return;
            }
            this.selectedSpec = spec;
          }
        }
      } finally {
        this.runningAction = false;
      }
    },

    async loadSpecs() {
      this.specs = await this.$store.$coreApi.coreConnectorApi.getSpecs(
        this.selectedDomain
      );
    },

    getAllSpecs() {
      return this.specs;
    },

    selectSpec(spec) {
      if (!this.runningAction) {
        this.selectedSpec = spec;
        this.$router.replace({ name: "specDetail", params: { spec: spec.id } });
        this.$emit("input", spec);
      }
    },

    closeDialog(reload) {
      this.createSpec = false;
      if (reload) this.init();
    },

    async closeSpecDetails(reload) {
      await this.$router.push({
        name: "specs",
        query: this.$route.query,
      });
      this.selectedSpec = null;
      if (reload) await this.loadSpecs();
    },

    getItemClass(item) {
      let itemClass = "spec-item";
      if (item.id === this.selectedSpec?.id) itemClass += " selected";
      return itemClass;
    },

    async openSpecDialog() {
      await this.closeSpecDetails();
      this.createSpec = true;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    tableHeight() {
      return this.getPageHeight() - 80;
    },

    title() {
      return "Found " + this.specs.length + " specs";
    },

    headers() {
      return [
        {
          text: "ID",
          value: "id",
          class: "spec-id-header",
          cellClass: "spec-id",
          groupable: false,
        },
        {
          text: "Connector",
          value: "connectorId",
          class: "spec-connector-header",
          cellClass: "spec-connector",
          groupable: true,
        },
        {
          text: "Service provider ID",
          value: "serviceProviderId",
          class: "spec-service-provider-header",
          cellClass: "spec-service-provider",
          groupable: false,
        },
      ];
    },

    showMinified() {
      return (
        this.getPageWidth() <= 900 ||
        (this.selectedSpec && this.tableWidth <= 900)
      );
    },
  },
};
</script>

<style scoped>
</style>