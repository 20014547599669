<template>
  <DialogForm
    :loading="runningAction"
    title="Create Spec"
    data-test-id="specDialog"
    save-btn-label="Create"
    close-btn-label="Cancel"
    @close="$emit('close', false)"
    @save="storeSpec"
  >
    <template #form="{ loading }">
      <v-form @submit.prevent ref="specForm">
        <v-select
          v-model="spec.connectorId"
          label="Connector"
          class="create-spec-connector"
          item-value="id"
          item-text="id"
          outlined
          dense
          data-test-id="specConnector"
          :items="connectors"
          :rules="inputRules"
          :disabled="loading"
        />
        <v-text-field
          v-model="spec.id"
          dense
          outlined
          label="Spec-ID"
          class="create-spec-id"
          data-test-id="specId"
          :rules="inputRules"
          :disabled="loading"
        />
        <v-select
          dense
          outlined
          v-model="spec.serviceProviderId"
          label="Service provider"
          class="create-spec-sps"
          item-text="name"
          item-value="id"
          data-test-id="specSps"
          :disabled="loading"
          :rules="inputRules"
          :items="serviceProviders"
        />
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import DialogForm from "../../common/templates/DialogForm";
export default {
  components: {
    DialogForm,
  },

  data() {
    return {
      spec: {},
      serviceProviders: [],
      connectors: [],
      inputRules: [(value) => !!value || "Value is required"],
      runningAction: false,
    };
  },

  async mounted() {
    try {
      this.runningAction = true;
      await Promise.all([this.loadServiceProviders(), this.loadConnectors()]);
    } finally {
      this.runningAction = false;
    }
  },

  methods: {
    async loadServiceProviders() {
      this.serviceProviders =
        await this.$store.$coreApi.coreConnectorApi.getServiceProviders(
          this.selectedDomain
        );
    },

    async loadConnectors() {
      this.connectors =
        await this.$store.$coreApi.coreConnectorApi.getConnectors();
    },

    async storeSpec() {
      try {
        if (this.$refs.specForm.validate()) {
          this.runningAction = true;

          //Check for already existing spec
          const duplicate = await this.$store.$coreApi.coreConnectorApi.getSpec(
            this.selectedDomain,
            this.spec.id
          );

          if (duplicate) {
            this.$store.commit(
              "SET_ERROR",
              "Spec with ID " + this.spec.id + " does already exist!"
            );
            return;
          }

          const res = await this.$store.$coreApi.coreConnectorApi.upsertSpec(
            this.selectedDomain,
            this.spec,
            "Spec " + this.spec.id + " created"
          );

          if (!res?.ok) return;

          this.$emit("close", true);
        }
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },
  },
};
</script>

<style scoped>
</style>